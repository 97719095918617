import React from "react";
import { useTranslation } from "react-i18next";
import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { ReactComponent as WarningIcon } from "../assets/warning.svg";
import { Page } from "../styles/Page.styles";

interface MinWidthPageProps {
    notSupportedBreakpoint?: Breakpoints;
}

const MinWidthPage: React.FC<MinWidthPageProps> = ({ children, notSupportedBreakpoint = Breakpoints.S }) => {
    const { t } = useTranslation();
    const hasNotSupportedWidth = useHasMaxWidth(notSupportedBreakpoint);
    return (
        <Page>
            {hasNotSupportedWidth ? (
                <Stack flex="1" marginVertical="L" direction="column" justifyContent="center" alignItems="center">
                    <WarningIcon />
                    <Stack direction="column" alignItems="center" marginTop="S">
                        <Text bold>{t("sizeNotSupportedPage.title")}</Text>
                        <Text small>{t("sizeNotSupportedPage.subtitle")}</Text>
                    </Stack>
                </Stack>
            ) : (
                children
            )}
        </Page>
    );
};

export default MinWidthPage;
