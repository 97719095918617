import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { ErrorModel } from "../../models/ErrorModel";
import { appActions } from "../reducers/AppSlice";
import { organizationApi } from "../services/OrganizationApi";

const CUSTOM_ERROR_HANDLING_ENDPOINTS = [
    organizationApi.endpoints.createGroups.name,
    organizationApi.endpoints.addLocationsToGroup.name,
    organizationApi.endpoints.editGroupName.name,
    organizationApi.endpoints.editGroupParent.name,
    organizationApi.endpoints.deleteGroupLocations.name,
    organizationApi.endpoints.createGroups.name,
];

const errorMiddleware: Middleware =
    ({ dispatch }: MiddlewareAPI) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status !== 401) {
            const hasCustomHandling = CUSTOM_ERROR_HANDLING_ENDPOINTS.includes(action.meta.arg.endpointName);
            if (!hasCustomHandling) {
                const error: ErrorModel = {
                    title: action.error?.data?.message,
                    timestamp: new Date().getTime(),
                    status: action.payload.status,
                };
                dispatch(appActions.setAppError(error));
            }
        }
        if (isRejectedWithValue(action) && action.payload.status === 401) {
            const error: ErrorModel = {
                title: action.error?.data?.message,
                timestamp: new Date().getTime(),
                status: action.payload.status,
            };
            dispatch(appActions.setAppError(error));
        }

        return next(action);
    };

export default errorMiddleware;
