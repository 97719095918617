import React from "react";
import Router from "../Router";
import { useUserLanguage } from "../store/hooks/UserHooks";

const AppLayout: React.FC = () => {
    useUserLanguage();

    return <Router />;
};

export default AppLayout;
