import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as JsSearch from "js-search";
import { NodeLabel } from "../../models/OrganizationNodeModel";
import OrganizationEditorSelectors from "../selectors/OrganizationEditorSelectors";
import { useLazyGetChildLocationsQuery } from "../services/OrganizationApi";
import LocationsSelectors from "../selectors/LocationsSelectors";
import GroupsSelectors from "../selectors/GroupsSelectors";
import { getCountryName, getGroupNames, sortLocations } from "../../helpers/LocationsHelpers";
import { LocationType } from "../../models/LocationModel";

export const useLocations = () => {
    const selectedClient = useSelector(OrganizationEditorSelectors.selectSelectedClient);
    const [getChildLocationsQuery] = useLazyGetChildLocationsQuery();

    useEffect(() => {
        if (selectedClient) {
            const label = selectedClient.label === NodeLabel.Client ? NodeLabel.SiteObject : NodeLabel.CustomerObject;
            void getChildLocationsQuery({ clientId: selectedClient.id, childLabel: label });
        }
    }, [getChildLocationsQuery, selectedClient]);
};

export const useSortedLocations = () => {
    const locations = useSelector(LocationsSelectors.selectLocations);
    const searchStr = useSelector(LocationsSelectors.selectSearchStr);
    const sortSettings = useSelector(LocationsSelectors.selectSortSettings);
    const groups = useSelector(GroupsSelectors.selectGroups);
    const { t, i18n } = useTranslation();

    const formattedLocations = useMemo(
        () =>
            locations.map((x) => {
                const groupNames = getGroupNames(groups, x, t);

                return {
                    ...x,
                    country: x.countryCode ? getCountryName(x.countryCode, i18n.language) : null,
                    groups: groupNames,
                    groupsSearch: groupNames.map((g) => g.label),
                };
            }),
        [locations, groups, t, i18n]
    );

    const searchResults = useMemo(() => {
        const search = new JsSearch.Search("id");
        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.addIndex("name");
        search.addIndex("address");
        search.addIndex("country");
        search.addIndex("number");
        search.addIndex("groupsSearch");
        search.addDocuments(formattedLocations);

        if (searchStr) {
            return search.search(searchStr) as LocationType[];
        }
        return formattedLocations;
    }, [searchStr, formattedLocations]);

    const sortedLocations = useMemo(() => sortLocations(sortSettings, searchResults), [sortSettings, searchResults]);

    return sortedLocations;
};
