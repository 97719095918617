import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoadingIndicator, EmptyState, Stack } from "@secuis/ccp-react-components";
import { GroupItem } from "./GroupItem";
import { Table } from "./GroupsTable.styles";
import { GroupTreeModel } from "../../models/GroupModel";
import GroupsSelectors from "../../store/selectors/GroupsSelectors";
import { CenteredContent } from "../../pages/CenteredContent";
interface GroupsTableProps {
    onOpenGroupsModal: (id?: string) => void;
    setSelectedGroup: (group: GroupTreeModel) => void;
}

export const GroupsTable = ({ onOpenGroupsModal, setSelectedGroup }: GroupsTableProps): JSX.Element => {
    const { t } = useTranslation();
    const groupsTree = useSelector(GroupsSelectors.selectGroupsTree);
    const isLoading = useSelector(GroupsSelectors.selectIsLoading);

    if (isLoading) {
        return (
            <CenteredContent>
                <LoadingIndicator size="L" />
            </CenteredContent>
        );
    }

    const renderRecursiveGroups = (groups: GroupTreeModel[], index: number) => {
        return groups.map((g) => (
            <GroupItem onOpenEditGroupModal={() => setSelectedGroup(g)} onOpenGroupsModal={onOpenGroupsModal} group={g} key={g.id} index={index}>
                {renderRecursiveGroups(g.children, index + 1)}
            </GroupItem>
        ));
    };
    return (
        <Table>
            {groupsTree.length === 0 ? (
                <CenteredContent>
                    <EmptyState
                        icon="LocationGroup"
                        title={t("groups.groupsTable.empty.title")}
                        subtitle={t("groups.groupsTable.empty.subTitle")}
                        action={{
                            icon: "Plus",
                            onClick: () => onOpenGroupsModal(),
                            children: t("createGroupsPage.createGroupButton"),
                        }}
                    />
                </CenteredContent>
            ) : (
                renderRecursiveGroups(groupsTree, 0)
            )}
        </Table>
    );
};
