import React from "react";
import { Icon, IconType, Stack, Text } from "@secuis/ccp-react-components";
import { IllustrationCircle } from "./BannerIllustration.styles";

interface BannerIllustrationProps {
    label: string;
    variant: IconType;
}

const BannerIllustration: React.FC<BannerIllustrationProps> = ({ label, variant }) => {
    return (
        <Stack gap="XS" alignItems="center">
            <IllustrationCircle>
                <Icon size="S" color="secondary" variant={variant} />
            </IllustrationCircle>
            <Text small color="secondary">
                {label}
            </Text>
        </Stack>
    );
};

export default BannerIllustration;
