import "./wdyr";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { FontStyles, GlobalStyles, Toaster } from "@secuis/ccp-react-components";
import { store } from "./Store";
import App from "./App";
import SplashScreen from "./components/SplashScreen";
import "@secuis/ccp-react-components/dist/index.esm.css"; // load styles from SCL
import "./i18next";

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        responseType="id_token"
        scope="openid profile email"
    >
        <Provider store={store}>
            <FontStyles />
            <GlobalStyles />
            <Suspense fallback={<SplashScreen />}>
                <BrowserRouter>
                    <Toaster>
                        <App />
                    </Toaster>
                </BrowserRouter>
            </Suspense>
        </Provider>
    </Auth0Provider>,
    document.getElementById("root")
);
