export enum AppsEnum {
    MobileGuarding = "MobileGuarding",
    RemoteServices = "RemoteServices",
    EssentialReports = "EssentialReports",
    Corporate = "Corporate",
    IssueReporting = "IssueReporting",
    Global = "Global",
    IdProtection = "IdProtection",
    Settings = "Settings",
    UserManagement = "UserManagement",
    Groups = "Groups",
}
