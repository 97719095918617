import { RootState } from "../../Store";

const selectHub = ({ app: { hub } }: RootState) => hub;

const selectSignOnToken = ({ app: { signOnToken } }: RootState) => signOnToken;

const selectLanguages = ({ app: { languages } }: RootState) => languages;

const selectAppErrors = ({ app: { errors } }: RootState) => errors;

const selectBrandingInfo = ({ app: { brandingInfo } }: RootState) => brandingInfo;

export default {
    selectHub,
    selectSignOnToken,
    selectLanguages,
    selectAppErrors,
    selectBrandingInfo,
};
