import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";

const selectLocations = ({ locations: { locations } }: RootState) => locations;
const selectIsLoading = ({ locations: { isLoading } }: RootState) => isLoading;
const selectIsError = ({ locations: { isError } }: RootState) => isError;
const selectSortSettings = ({ locations: { sortSettings } }: RootState) => sortSettings;
const selectSearchStr = ({ locations: { searchStr } }: RootState) => searchStr;
const selectLocationsByIds = createSelector([selectLocations, (_, locationIDs: string[]) => locationIDs], (locations, locationIDs) =>
    locations.filter((location) => locationIDs.includes(location.id))
);

export default {
    selectLocations,
    selectIsLoading,
    selectIsError,
    selectLocationsByIds,
    selectSortSettings,
    selectSearchStr,
};
