import { Breakpoints, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";
import { PagePadding } from "./variables/layout";

export const Page = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Section = styled(Stack)`
    padding: 0 ${PagePadding.L}px;

    @media (max-width: ${Breakpoints.L}) {
        padding: 0 ${PagePadding.M}px;
    }
`;

export const TopSection = styled(Section)`
    padding-top: ${PagePadding.L}px;

    @media (max-width: ${Breakpoints.L}) {
        padding-top: ${PagePadding.M}px;
    }
`;

export const BottomSection = styled(Section)`
    padding-bottom: ${PagePadding.L}px;
    overflow: hidden;
    flex: 1;

    @media (max-width: ${Breakpoints.L}) {
        padding-bottom: ${PagePadding.M}px;
    }
`;

export const Wrapper = styled(Stack)`
    height: 100%;
    width: 100%;
`;
