import { configureStore } from "@reduxjs/toolkit";
import { accessApi } from "./store/services/AccessApi";
import { appApi } from "./store/services/AppApi";
import { authorizationApi } from "./store/services/AuthorizationApi";
import { organizationApi } from "./store/services/OrganizationApi";
import { userApi } from "./store/services/UserApi";
import { accessSlice } from "./store/reducers/AccessSlice";
import { appSlice } from "./store/reducers/AppSlice";
import { authSlice } from "./store/reducers/AuthSlice";
import { groupsSlice } from "./store/reducers/GroupsSlice";
import { locationsSlice } from "./store/reducers/LocationsSlice";
import { organizationSlice } from "./store/reducers/OrganizationSlice";
import { organizationEditorSlice } from "./store/reducers/OrganizationEditorSlice";
import { rolesSlice } from "./store/reducers/RolesSlice";
import { userSlice } from "./store/reducers/UserSlice";

import errorMiddleware from "./store/middleware/errorMiddleware";
import refreshMiddleware from "./store/middleware/refreshMiddleware";
import unauthenticatedMiddleware from "./store/middleware/unauthicatedMiddleware";

const middlewares = [
    unauthenticatedMiddleware,
    errorMiddleware,
    refreshMiddleware,
    accessApi.middleware,
    appApi.middleware,
    authorizationApi.middleware,
    organizationApi.middleware,
    userApi.middleware,
];

const apiReducers = {
    [accessApi.reducerPath]: accessApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    [authorizationApi.reducerPath]: authorizationApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
};

export const sliceReducers = {
    [accessSlice.name]: accessSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [organizationSlice.name]: organizationSlice.reducer,
    [organizationEditorSlice.name]: organizationEditorSlice.reducer,
    [rolesSlice.name]: rolesSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [locationsSlice.name]: locationsSlice.reducer,
    [groupsSlice.name]: groupsSlice.reducer,
};

export const store = configureStore({
    reducer: {
        ...apiReducers,
        ...sliceReducers,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
