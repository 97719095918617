import { Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const IllustrationCircle = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Palette.Navy750};
`;
