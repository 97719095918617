import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${Breakpoints.XS}) {
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const Content = styled.div`
    margin: ${Spacing.M}px ${Spacing.S}px;
`;

export const TitleWrapper = styled.div`
    color: ${Palette.Purple300};
`;

export const ButtonWrapper = styled.div`
    margin-top: ${Spacing.S}px;
`;
