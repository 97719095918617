import { Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TableWrapper = styled(Stack)`
    flex: 1;
    background-color: ${Palette.Navy800};
    border-radius: 8px;
    padding: ${Spacing.S}px;
    margin-top: ${Spacing.S}px;
    overflow: hidden;
`;
