import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { UserModel } from "../../models/UserModel";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery,
    endpoints: (builder) => ({
        getUser: builder.query<UserModel, string>({
            query: (userId) => ({
                url: `/v1/users/byId/${userId}`,
            }),
        }),
    }),
});

export const { useLazyGetUserQuery } = userApi;
