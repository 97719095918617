import { GroupEditorType, GroupModel, GroupTreeModel } from "../models/GroupModel";
import { CreateGroupRequest } from "../store/services/Models";

export const sortGroups = (groups: GroupModel[] = []): GroupModel[] => {
    return [...groups].sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
};

export const mapGroupsToTreeFromRoot = (groups: GroupModel[], id: string): GroupTreeModel[] => {
    const children = groups.filter((item) => item.parentId === id);

    return children.map((item) => {
        const child = { ...item, locationsCount: 0, headPosition: 0, tailPosition: 0 } as GroupTreeModel;
        child.children = mapGroupsToTree(groups, child);
        return child;
    });
};

const mapGroupsToTree = (groups: GroupModel[], parent: GroupTreeModel): GroupTreeModel[] => {
    const children = groups.filter((item) => item.parentId === parent.id);

    return children.map((item) => {
        const child = { ...item, locationsCount: 0, parent, parentId: parent.id, headPosition: parent.headPosition + 1 } as GroupTreeModel;
        child.children = mapGroupsToTree(groups, child);
        if (!child.children.length) {
            child.tailPosition = 0;
            updateLocationCount(parent, child.locationIds.length, 1);
        }
        delete child.parent;
        return child;
    });
};

const updateLocationCount = (group: GroupTreeModel, inheritedLocationCount: number, tailPosition: number) => {
    if (!group) {
        return;
    }
    if (!group.tailPosition || tailPosition > group.tailPosition) {
        group.tailPosition = tailPosition;
    }
    group.locationsCount += inheritedLocationCount;
    const newCount = inheritedLocationCount + (!group.locationsUpdated ? group.locationIds.length : 0);
    updateLocationCount(group.parent, newCount, tailPosition + 1);
    group.locationsUpdated = true;
};

export const getParentsPath = (groups: GroupModel[], groupEditor?: GroupEditorType): string[] => {
    const getParentId = (group: GroupModel | GroupTreeModel, parentsIds: string[]): string[] => {
        if (!group) {
            return parentsIds;
        }
        const parent = groups.find((g) => g.id === group.parentId);
        if (parent) {
            return getParentId(parent, [parent.parentId, ...parentsIds]);
        }
        return [...parentsIds, group.parentId];
    };
    return groupEditor ? getParentId(groupEditor, [groupEditor.parentId]) : [];
};

export const getChildren = (group: GroupTreeModel): GroupTreeModel[] => {
    const result = [];
    const getItems = (group: GroupTreeModel) => {
        result.push(...group.children);
        group.children.forEach((c) => getItems(c));
    };
    getItems(group);

    return result;
};

export const getGroupsTreeByIds = (groupTree: GroupTreeModel[], ids: string[]): GroupTreeModel[] =>
    groupTree
        .map(getChildren) // all children
        .flat()
        .concat(groupTree) // top level groups
        .filter((g) => ids.includes(g.id));

export const getIds = (group: GroupTreeModel, id: string): string[] => {
    return [id, ...group.children.map((child) => getIds(child, child.id)).flat()];
};

export const findGroupLevel = (id: string, tree: GroupTreeModel[], isTail = false): number => {
    const key = isTail ? "tailPosition" : "headPosition";
    const level = tree.find((group) => group.id === id)?.[key];
    if (level === undefined) {
        const levelArr = tree
            .map((g) => findGroupLevel(id, g.children, isTail))
            .flat()
            .filter((item) => item !== undefined);

        return levelArr[0];
    }
    return level;
};

export const checkParentMaxNestingLevel = (groupOptionId: string, groupEditor: GroupEditorType, groupsTree: GroupTreeModel[], maxLevel: number): boolean => {
    const children = getChildren(groupEditor);
    const childrenTailIndexes = children.map((c) => c.tailPosition);
    const biggestTailIndex = Math.max(...childrenTailIndexes);
    const optionHeadLevel = findGroupLevel(groupOptionId, groupsTree);
    const groupTailPosition = childrenTailIndexes.length ? biggestTailIndex + 1 : groupEditor.tailPosition;
    const hasExceedMaxLevel = optionHeadLevel + groupTailPosition >= maxLevel;
    return hasExceedMaxLevel;
};

export const checkChildMaxNestingLevel = (groupOptionId: string, groupEditor: GroupEditorType, groupsTree: GroupTreeModel[], maxLevel: number): boolean => {
    const optionTailLevel = findGroupLevel(groupOptionId, groupsTree, true);
    const groupHeadPosition = findGroupLevel(groupEditor.parentId, groupsTree);
    const hasExceedMaxLevel = optionTailLevel + (!isNaN(groupHeadPosition) ? groupHeadPosition + 1 : groupEditor.headPosition) >= maxLevel;
    return hasExceedMaxLevel;
};
const isMatchByStr = (matchStr: string) => (str: string) => {
    const regexpOptions = "(?:s(d+))?\\b";
    const regex = new RegExp("\\b" + matchStr + regexpOptions, "g");
    return str.match(regex);
};

export const formatCreateGroupRequestParams = (params: CreateGroupRequest[], groupList: GroupModel[]): CreateGroupRequest[] => {
    const availableNames = [];
    params.forEach(({ name, parentId }) => {
        const siblingNames = groupList.filter((g) => g.parentId === parentId).map((s) => s.name);
        const similarExistingNames = siblingNames.filter(isMatchByStr(name));
        const similarAvailableNames = availableNames.filter(isMatchByStr(name));
        if (!similarExistingNames.length && !similarAvailableNames.length) {
            availableNames.push(name);
        } else if (!similarExistingNames.length && similarAvailableNames.length) {
            similarAvailableNames.every((_, index) => {
                if (!similarAvailableNames.includes(name)) {
                    availableNames.push(name);
                    return false;
                } else if (!similarAvailableNames.includes(`${name} (${index + 1})`)) {
                    availableNames.push(`${name} (${index + 1})`);
                    return false;
                }
                return true;
            });
        } else {
            const allNames = similarExistingNames.concat(similarAvailableNames);
            allNames.every((_, index) => {
                if (!allNames.includes(name)) {
                    availableNames.push(name);
                    return false;
                } else if (!allNames.includes(`${name} (${index + 1})`)) {
                    availableNames.push(`${name} (${index + 1})`);
                    return false;
                }
                return true;
            });
        }
    });
    return params.map((p, index) => ({ ...p, name: availableNames[index] }));
};

export const formatNameRequestParam = (name: string, parentId: string, groupList: GroupModel[]): string => {
    let newName;
    const siblingNames = groupList.filter((g) => g.parentId === parentId).map((s) => s.name);
    const similarNames = siblingNames.filter(isMatchByStr(name));
    similarNames.forEach((_, index) => {
        if (newName) {
            return;
        }
        if (!similarNames.includes(name)) {
            newName = name;
        }
        if (!similarNames.includes(`${name} (${index + 1})`)) {
            newName = `${name} (${index + 1})`;
        }
    });
    return newName || name;
};
