import { createSlice } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { organizationApi } from "../services/OrganizationApi";
import { GroupModel } from "../../models/GroupModel";
import { sortGroups } from "../../helpers/GroupsHelpers";
interface GroupsStateModel {
    groups: GroupModel[];
    isLoading: boolean;
    isError: boolean;
    error?: FetchBaseQueryError;
}

export const initialState: GroupsStateModel = {
    groups: [],
    isLoading: false,
    isError: false,
    error: null,
};

export const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(organizationApi.endpoints.getGroups.matchFulfilled, (state, action) => {
                if (action.payload.groups) {
                    state.groups = sortGroups(action.payload.groups);
                }
                state.isLoading = false;
            })
            .addMatcher(organizationApi.endpoints.getGroups.matchRejected, (state) => {
                state.isLoading = false;
            })
            .addMatcher(organizationApi.endpoints.getGroups.matchPending, (state) => {
                state.isLoading = true;
            })
            .addMatcher(organizationApi.endpoints.addLocationsToGroup.matchRejected, (state) => {
                state.isLoading = false;
            });
    },
});
