import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const InputWrapper = styled(Stack)`
    // label ellipsis is absolute and needs additional top-margin to be properly positioned
    margin-top: 14px;
    align-items: center;

    > div {
        flex: 1;
    }
`;
