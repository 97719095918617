import { createSlice } from "@reduxjs/toolkit";
import { organizationApi } from "../services/OrganizationApi";
import { mapToOrganizationNode } from "../../helpers/OrganizationNodeHelper";
import { NodeModel } from "../../models/OrganizationNodeModel";
import { SortOrderType } from "../../helpers/LocationsHelpers";

interface LocationsStateModel {
    locations: NodeModel[];
    isLoading: boolean;
    isError: boolean;
    sortSettings: { sortBy: string; order: SortOrderType };
    searchStr: string;
}

export const initialState: LocationsStateModel = {
    locations: [],
    isLoading: false,
    isError: false,
    sortSettings: { sortBy: "country", order: "asc" },
    searchStr: "",
};

export const locationsSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {
        changeSortSettings(state, { payload }) {
            state.sortSettings = payload;
        },
        changeSearchStr(state, { payload }) {
            state.searchStr = payload;
        },
        resetSortSettings(state) {
            state.sortSettings = initialState.sortSettings;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(organizationApi.endpoints.getChildLocations.matchFulfilled, (state, action) => {
                state.locations = action.payload.map((val) => mapToOrganizationNode(val, ["address", "city", "postCode", "state"]));
                state.isLoading = false;
                state.isError = false;
            })
            .addMatcher(organizationApi.endpoints.getChildLocations.matchRejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addMatcher(organizationApi.endpoints.getChildLocations.matchPending, (state) => {
                state.isLoading = true;
                state.isError = false;
            });
    },
});

export const locationsActions = locationsSlice.actions;
