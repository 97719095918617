import { ExpirationTokenModel } from "../models/TokenModel";

export const tokenExpiresInSeconds = (tokenDecoded: ExpirationTokenModel) => {
    return tokenDecoded.exp - Math.floor(Date.now() / 1000);
};

export const hasTokenExpired = (tokenDecoded: ExpirationTokenModel) => {
    return tokenExpiresInSeconds(tokenDecoded) < 0;
};

export const hasTokenHalftimePassed = (decodedToken: ExpirationTokenModel) => {
    const threshold = (decodedToken.exp - decodedToken.iat) / 2; // refresh threshold is half the time of token lifespan
    const expiresInSeconds = tokenExpiresInSeconds(decodedToken);
    const tokenNeedsRefresh = expiresInSeconds < threshold;
    return tokenNeedsRefresh;
};
