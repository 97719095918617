import React from "react";
import { useTranslation } from "react-i18next";
import { Headline, Text } from "@secuis/ccp-react-components";
import ClientCard from "./ClientCard";
import { ScrollableStack, CardsSection } from "./ClientsList.styles";
import { Section } from "../../../styles/Page.styles";

type ClientItem = {
    id: string;
    badgeLabel: string;
    title: string;
    subtitle?: string;
};

interface ClientsListProps {
    items: ClientItem[];
    selectedClientId?: string;
    error?: string;
    onSelectedClientChange: (id: string) => void;
}

const ClientsList: React.FC<ClientsListProps> = ({ items, error, selectedClientId, onSelectedClientChange }) => {
    const { t } = useTranslation();
    return (
        <ScrollableStack direction="column" gap={error ? "XS" : "S"}>
            <Section direction="column">
                <Headline bold>{t("selectClient.list.header")}</Headline>
                {error && (
                    <Text micro color="high">
                        {error}
                    </Text>
                )}
            </Section>
            <CardsSection direction="column" gap="XS">
                {items.map((c) => (
                    <ClientCard
                        id={c.id}
                        key={c.id}
                        badgeLabel={c.badgeLabel}
                        title={c.title}
                        subtitle={c.subtitle}
                        selected={selectedClientId === c.id}
                        onSelect={() => onSelectedClientChange(c.id)}
                    />
                ))}
            </CardsSection>
        </ScrollableStack>
    );
};

export default ClientsList;
