import { useCallback, useState } from "react";
import { getIds } from "../helpers/GroupsHelpers";
import { GroupTreeModel } from "../models/GroupModel";
import { useDeleteGroupMutation, useLazyGetGroupsQuery } from "../store/services/OrganizationApi";
import { useLazyGetAuthRecordsByLocationQuery } from "../store/services/AuthorizationApi";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";
import { useSelector } from "react-redux";

export const PEOPLE_ONBOARDED_ERROR = "HasPeopleAssigned";

export class CustomError extends Error {
    constructor(message: string) {
        super(message);
        this.message = message;

        Object.setPrototypeOf(this, CustomError.prototype);
    }
}

export const useDeleteGroup = () => {
    const [isLoading, setIsloading] = useState(false);
    const [getAuthRecords] = useLazyGetAuthRecordsByLocationQuery();
    const [requestDeleteGroup] = useDeleteGroupMutation();
    const deleteGroup = useCallback(
        async (idsToRemove: string[]) => {
            setIsloading(true);
            try {
                for (const id of idsToRemove) {
                    // Check for auth records in group
                    const authRecordsResult = await getAuthRecords({
                        id,
                    }).unwrap();
                    if ((authRecordsResult as { id: string }[]).length) {
                        throw new CustomError(PEOPLE_ONBOARDED_ERROR);
                    }
                }
                for (const id of idsToRemove) {
                    await requestDeleteGroup({
                        id,
                        checkAccess: true,
                    }).unwrap();
                }
                for (const id of idsToRemove) {
                    await requestDeleteGroup({
                        id,
                        checkAccess: false,
                    }).unwrap();
                }
            } finally {
                setIsloading(false);
            }
        },
        [requestDeleteGroup, getAuthRecords]
    );

    return {
        deleteGroup,
        isLoading,
    };
};
