import { createSlice } from "@reduxjs/toolkit";
import { RoleModel } from "../../models/RoleModel";
import { authorizationApi } from "../services/AuthorizationApi";

interface RolesStateModel {
    rolesByLocation: Record<string, RoleModel[]>;
}

export const initialState: RolesStateModel = {
    rolesByLocation: {},
};

export const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(authorizationApi.endpoints.getAuthorizedRoles.matchFulfilled, (state, action) => {
            const nodeId = action.meta.arg.originalArgs.locationId;
            state.rolesByLocation[nodeId] = action.payload;
        });
    },
});
