import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonText, Headline, Icon, Overlay, Separator, Stack, Text, useDebounce } from "@secuis/ccp-react-components";
import { Body, Content, Footer, Header, Wrapper } from "./Modal.styles";

interface ModalAction {
    id: string;
    children: ReactNode;
    loading: boolean;
    onClick: () => void;
    disabled?: boolean;
    isPrimary?: boolean;
}

interface ModalProps {
    isOpen: boolean;
    title: string;
    actions: ModalAction[];
    headerChildren?: ReactNode;
    children?: ReactNode;
    onClose: () => void;
    disabled?: boolean;
    footerError?: string;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, title, actions, children, onClose, disabled, headerChildren, footerError }: ModalProps) => {
    const { t } = useTranslation();
    const [isStickyHeader, setIsStickyHeader] = useState(false);
    const debounceIsStickyHeader = useDebounce(isStickyHeader, 100);

    const handleBodyScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const element = event.currentTarget as HTMLDivElement;
        setIsStickyHeader(element.scrollTop > 0);
    };
    return (
        <React.Fragment>
            {isOpen && <Overlay />}
            <Wrapper isOpen={isOpen} role="dialog">
                <Header scrolled={debounceIsStickyHeader} direction="column">
                    <ButtonText icon="Clear" disabled={disabled} onClick={onClose}>
                        {t("common.close")}
                    </ButtonText>
                </Header>
                <Content>
                    <Body onScroll={handleBodyScroll}>
                        <Box marginBottom="XS">
                            <Stack alignItems="center" justifyContent="space-between">
                                <Headline bold>{title}</Headline>
                                {headerChildren}
                            </Stack>
                        </Box>
                        {children}
                    </Body>
                </Content>
                <Separator />
                <Footer direction="column">
                    <Stack justifyContent="space-between" gap="M">
                        <Stack alignItems="center">
                            {footerError && (
                                <Stack alignItems="center" gap="XS">
                                    <Icon variant="Error" color="error" />
                                    <Text color="error">{footerError}</Text>
                                </Stack>
                            )}
                        </Stack>
                        <Stack alignItems="center" gap="M">
                            {actions.map(({ id, isPrimary = true, children: buttonChildren, onClick, loading, disabled }) => {
                                return isPrimary ? (
                                    <Button key={id} fitContent mode="contained" onClick={onClick} loading={loading} disabled={disabled}>
                                        {buttonChildren}
                                    </Button>
                                ) : (
                                    <Button key={id} fitContent mode="outlined" onClick={onClick} disabled={disabled}>
                                        {buttonChildren}
                                    </Button>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Footer>
            </Wrapper>
        </React.Fragment>
    );
};
