import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export type AccessResponse = {
    token: string;
};

export const accessApi = createApi({
    reducerPath: "accessApi",
    baseQuery,
    endpoints: (builder) => ({
        getAccessToken: builder.query<AccessResponse, string>({
            query: (idToken) => ({
                url: "/v1/authenticationToken",
                method: "POST",
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            }),
        }),
        getAccessTokenBySignOnToken: builder.query<AccessResponse, string>({
            query: (token) => ({
                url: "/v1/authenticationTokenBySignOnToken",
                method: "POST",
                body: {
                    token,
                },
            }),
        }),
        refreshAccessToken: builder.query<AccessResponse, void>({
            query: () => ({
                url: "/v1/authenticationTokenByToken",
            }),
        }),
    }),
});

export const { useLazyGetAccessTokenQuery, useLazyGetAccessTokenBySignOnTokenQuery, useLazyRefreshAccessTokenQuery } = accessApi;
