import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLazyGetGroupsQuery } from "../services/OrganizationApi";
import OrganizationEditorSelectors from "../selectors/OrganizationEditorSelectors";

export const useGroups = () => {
    const selectedClientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const [getGroups] = useLazyGetGroupsQuery();

    useEffect(() => {
        if (selectedClientId) {
            void getGroups(selectedClientId);
        }
    }, [selectedClientId, getGroups]);
};
