import { ButtonText, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const CHILD_LEFT_PADDING = 72;
export const ANIMATION_DURATION = 140;

export const GroupItemWrapper = styled.div<{ index: number; isOpen: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${Spacing.S}px ${Spacing.M}px;
    padding-left: calc(${Spacing.M}px + ${({ index }) => CHILD_LEFT_PADDING * index}px);
    background: ${Palette.Navy750};
    border-radius: 2px;
    margin: 0 ${Spacing.S}px ${({ index, isOpen }) => (index || isOpen ? Spacing.XXS : Spacing.XS)}px;
`;

export const GroupItemName = styled(Stack)`
    flex: 1 1 auto;
    min-width: 0;
    word-break: break-all;
`;

export const OptionsColumn = styled(Stack)`
    justify-content: space-between;
    margin-left: ${Spacing.L}px;
    align-items: center;
    height: ${Spacing.XL}px;
    width: 300px;
`;

export const StyledButtonText = styled(ButtonText)<{ isOpen: boolean }>`
    margin-right: ${Spacing.XS}px;
    svg {
        transition: transform ${ANIMATION_DURATION}ms linear;
        transform: rotate(${({ isOpen }) => (isOpen ? "90deg" : "0")});
    }
`;

export const ChildrenWrapper = styled.div<{ isOpen: boolean; index: number }>`
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: opacity ${ANIMATION_DURATION}ms;
`;

export const BadgesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 280px;
    row-gap: 8px;
`;
