import { ToasterContext } from "@secuis/ccp-react-components";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { appActions, HubEnum } from "../reducers/AppSlice";
import AppSelectors from "../selectors/AppSelectors";

export const usePopulateStateFromQueryString = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const hubParam = searchParams.get("a");
        if (hubParam) {
            dispatch(appActions.setEmbeddingHub(hubParam as HubEnum));
        }

        const tokenParam = searchParams.get("t");
        if (tokenParam) {
            dispatch(appActions.setSignOnToken(tokenParam));
        }

        // language codes are passed from embedding Hub app, it returns languages only for user's available apps
        const languagesParam = searchParams.get("languages");
        if (languagesParam) {
            dispatch(appActions.setLanguages(languagesParam.split(",")));
        }
    }, []); // effect to run only once
};

export const useAppErrors = (isReady: boolean) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { addToast } = useContext(ToasterContext);
    const errors = useSelector(AppSelectors.selectAppErrors);

    useEffect(() => {
        if (isReady && errors.length) {
            // take first error
            const error = errors[0];

            // determine status and therefore title
            const isServerError = error.status >= 500;
            const isNetworkError = error.status >= 400 && error.status < 500;
            const isAccessDenied = error.status === 401;

            let title = t("errors.genericError.title");
            if (isServerError) {
                title = t("errors.serverError.title");
            }
            if (isNetworkError) {
                title = t("errors.networkError.title");
            }
            if (isAccessDenied) {
                title = t("common.accessDenied");
            }

            addToast({
                title,
                type: "high",
            });

            // error was shown so remove it from state
            dispatch(appActions.removeAppError(error));
        }
    }, [isReady, errors, t]);
};
