import { ChipFilter, Stack, Text } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { GroupEditorType } from "../../models/GroupModel";
import { useSelector } from "react-redux";
import LocationsSelectors from "../../store/selectors/LocationsSelectors";

interface EditGroupLocationsProps {
    groupEditor: GroupEditorType;
    onRemoveLocation: (locationId: string) => void;
}

export const EditGroupLocations = ({ groupEditor, onRemoveLocation }: EditGroupLocationsProps): JSX.Element => {
    const { t } = useTranslation();
    const locations = useSelector((state) => LocationsSelectors.selectLocationsByIds(state, groupEditor.locationIds));

    if (!locations.length) {
        return null;
    }

    return (
        <>
            <Text color="neutral" micro uppercase>
                {t("groups.edit.locations", { count: groupEditor.locationIds.length })}
            </Text>
            <Stack gap="XS" flexWrap="wrap">
                {locations.map(({ id, name }) => (
                    <ChipFilter key={id} color="accent" label={name} onRemove={() => onRemoveLocation(id)} />
                ))}
            </Stack>
        </>
    );
};
