import { FetchBaseQueryError, FetchBaseQueryMeta, createApi } from "@reduxjs/toolkit/query/react";
import { NodeDTO } from "../../models/OrganizationNodeModel";
import baseQuery from "./baseQuery";
import { CreateGroupRequest, CreateGroupResponse, MutationResponse } from "./Models";
import { RootState } from "../../Store";
import { formatCreateGroupRequestParams } from "../../helpers/GroupsHelpers";

const mapResult = (result: { data?: unknown; error?: FetchBaseQueryError; meta?: FetchBaseQueryMeta }) => ({
    data: result.data,
    status: result.meta.response?.status,
    error: result.error,
});

export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery,
    endpoints: (builder) => ({
        getAuthorizedNodes: builder.query<NodeDTO[], string[]>({
            query: (locationIds) => ({
                url: `/v1/organization/byLocationIds`,
                method: "POST",
                body: { locationIds },
            }),
        }),
        getChildNodes: builder.query<NodeDTO[], string>({
            query: (nodeId) => ({
                url: `/v1/organization/byLocationId/${nodeId}/children`,
            }),
        }),
        getChildLocations: builder.query({
            query: (params) => ({
                url: `/v1/organization/labeledvertices/${params.clientId}/${params.childLabel}/site`,
            }),
        }),
        getGroups: builder.query({
            query: (params) => ({
                url: `/v1/organization/${params}/groups`,
            }),
        }),
        addLocationsToGroup: builder.mutation<unknown, { groupId: string; locationIds: string[] }>({
            query: (params) => ({
                url: `/v1/organization/groups/${params.groupId}/locations`,
                method: "PATCH",
                body: { locationIds: params.locationIds },
            }),
        }),
        createGroups: builder.mutation<MutationResponse<CreateGroupResponse>[], CreateGroupRequest[]>({
            async queryFn(params, { getState }, _b, fetchWithBQ) {
                const results = [];
                const {
                    groups: { groups: groupList },
                } = getState() as RootState;
                const formattedParams = formatCreateGroupRequestParams(params, groupList);

                for (const p of formattedParams) {
                    const result = await fetchWithBQ({
                        url: `/v1/organization/${p.parentId}/groups`,
                        method: "POST",
                        body: { name: p.name, locationIds: p.locationIds },
                    });
                    results.push(mapResult(result));
                }

                return { data: results };
            },
        }),
        deleteGroup: builder.mutation<unknown, { id: string; checkAccess: boolean }>({
            query: ({ id, checkAccess }) => ({
                url: `/v1/organization/groups/${id}`,
                method: "DELETE",
                headers: checkAccess ? [["x-check-if-allowed", "true"]] : undefined,
            }),
        }),
        editGroupName: builder.mutation<unknown, { groupId: string; name: string }>({
            query: (params) => ({
                url: `/v1/organization/groups/${params.groupId}/name`,
                method: "PUT",
                body: { name: params.name },
            }),
        }),
        editGroupParent: builder.mutation<unknown, { groupId: string; parentId: string }>({
            query: ({ groupId, parentId }) => ({
                url: `/v1/organization/groups/${groupId}/parent`,
                method: "PUT",
                body: { parentId },
            }),
        }),
        deleteGroupLocations: builder.mutation<unknown, { groupId: string; locationIds: string[] }>({
            query: ({ groupId, locationIds }) => ({
                url: `/v1/organization/groups/${groupId}/locations`,
                method: "DELETE",
                body: { locationIds },
            }),
        }),
    }),
});

export const {
    useLazyGetAuthorizedNodesQuery,
    useLazyGetChildLocationsQuery,
    useLazyGetGroupsQuery,
    useAddLocationsToGroupMutation,
    useCreateGroupsMutation,
    useDeleteGroupMutation,
    useEditGroupNameMutation,
    useEditGroupParentMutation,
    useDeleteGroupLocationsMutation,
} = organizationApi;
