import { RootState } from "../../Store";

const selectUser = ({ user: { user } }: RootState) => user;

const selectUserPreference =
    (preferenceKey: string) =>
    ({ user: { user } }: RootState) => {
        if (user?.preferences) {
            const preference = user.preferences.find(({ key }) => key === preferenceKey);
            return preference?.value;
        }
        return null;
    };

export default {
    selectUser,
    selectUserPreference,
};
