import { createSlice } from "@reduxjs/toolkit";
import { AuthRecordModel } from "../../models/AuthRecordModel";
import { authorizationApi } from "../services/AuthorizationApi";
import { areEqual } from "../../helpers/ArrayHelper";

interface AuthStateModel {
    records: AuthRecordModel[];
}

export const initialState: AuthStateModel = {
    records: [],
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(authorizationApi.endpoints.getAuthorizationRecords.matchFulfilled, (state, action) => {
            if (!state.records.length || !areEqual(state.records, action.payload)) {
                state.records = action.payload;
            }
        });
    },
});
