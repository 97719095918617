import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { hasTokenExpired } from "../../helpers/TokenHelper";
import { RootState } from "../../Store";
import { accessActions } from "../reducers/AccessSlice";

const unauthenticatedMiddleware: Middleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status === 401) {
            const state = getState() as RootState;
            const { accessTokenDecoded } = state.access;
            if (accessTokenDecoded && hasTokenExpired(accessTokenDecoded)) {
                dispatch(accessActions.setAccessExpired());
            }
        }

        return next(action);
    };

export default unauthenticatedMiddleware;
