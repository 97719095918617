import React, { useCallback, useContext, useState } from "react";
import { Badge, Breakpoints, ButtonText, ContextMenu, ToasterContext, useHasMaxWidth } from "@secuis/ccp-react-components";
import { GroupTreeModel } from "../../models/GroupModel";
import { ANIMATION_DURATION, BadgesWrapper, ChildrenWrapper, GroupItemWrapper, StyledButtonText, OptionsColumn, GroupItemName } from "./GroupItem.styles";
import { useTranslation } from "react-i18next";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { useSelector } from "react-redux";
import OrganizationEditorSelectors from "../../store/selectors/OrganizationEditorSelectors";
import { useLazyGetGroupsQuery } from "../../store/services/OrganizationApi";
import { getIds } from "../../helpers/GroupsHelpers";
import { IntercomContext } from "../Intercom";
import { IntercomTopic } from "../../models/IntercomModel";
import { AppsEnum } from "../../models/AppModel";
import { MAXIMUM_NESTING_LEVEL } from "../GroupsSteps/CreateGroups";
import { PEOPLE_ONBOARDED_ERROR, useDeleteGroup } from "../../hooks/DeleteGroupHooks";

interface GroupItemProps {
    group: GroupTreeModel;
    index: number;
    onOpenGroupsModal: (id: string) => void;
    onOpenEditGroupModal: () => void;
}

export const GroupItem: React.FC<GroupItemProps> = ({ group, index, children, onOpenGroupsModal, onOpenEditGroupModal }) => {
    const { t } = useTranslation();
    const selectedClientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const { addToast } = useContext(ToasterContext);
    const { deleteGroup, isLoading } = useDeleteGroup();
    const [getGroups] = useLazyGetGroupsQuery();
    const { postMessage } = useContext(IntercomContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const isMediumScreen = useHasMaxWidth(Breakpoints.L);
    // 2 variable for toggle children for animation purposes
    const [isOpen, setIsOpen] = useState(false);
    const [showChildren, setShowChildren] = useState(false);
    // dont add padding more than 4/6 levels
    const maxIndex = isMediumScreen ? 4 : 6;

    const hideElement = () => {
        setIsOpen(false);
        setTimeout(() => {
            setShowChildren(false);
        }, ANIMATION_DURATION);
    };

    const onToggleChildren = () => {
        if (isOpen) {
            hideElement();
        } else {
            setIsOpen(true);
            setShowChildren(true);
        }
    };

    const onConfirm = useCallback(async () => {
        const idsToRemove = getIds(group, group.id).reverse();
        try {
            await deleteGroup(idsToRemove);
            if (selectedClientId) {
                await getGroups(selectedClientId);
            }
            addToast({ title: t("groups.deleteGroup.successMessage", { count: idsToRemove.length }), preserveOnHover: false, type: "success" });
        } catch (error: any) {
            if (error?.message === PEOPLE_ONBOARDED_ERROR) {
                addToast({
                    type: "high",
                    title: t("error.cantDeleteGroup.title"),
                    message: t("error.cantDeleteOnboardedGroup.message"),
                    actions: [
                        {
                            label: t("groups.goToUserManagement"),
                            onClick: () => {
                                postMessage(IntercomTopic.REDIRECT, AppsEnum.UserManagement);
                            },
                        },
                    ],
                });
            }
        }
    }, [addToast, deleteGroup, getGroups, group, selectedClientId, t, postMessage]);

    const onDelete = useCallback(() => {
        if (group.children.length) {
            setIsModalOpen(true);
        } else {
            onConfirm();
        }
    }, [group, onConfirm]);

    const totalAmount = group.locationIds.length + (group.locationsCount ?? 0);

    return (
        <>
            <GroupItemWrapper isOpen={showChildren} index={index > maxIndex ? maxIndex : index} key={group.id}>
                <GroupItemName>
                    {!!group.children.length && <StyledButtonText isOpen={isOpen} onClick={onToggleChildren} icon="ArrowForward" />}
                    {group.name}
                </GroupItemName>
                <OptionsColumn>
                    <BadgesWrapper>
                        {!!group.locationsCount && <Badge label={t("group.totalSitesAmount", { count: totalAmount })} />}
                        {!!group.locationIds.length && <Badge mode="outlined" label={t("group.sitesAmount", { count: group.locationIds.length })} />}
                    </BadgesWrapper>
                    <ContextMenu
                        align="right"
                        options={[
                            {
                                icon: "Edit",
                                label: t("group.edit"),
                                onClick: onOpenEditGroupModal,
                            },
                            {
                                icon: "Plus",
                                label: t("group.createSubgroup"),
                                onClick: () => onOpenGroupsModal(group.id),
                                disabled: group.headPosition >= MAXIMUM_NESTING_LEVEL,
                            },
                            {
                                icon: "Delete",
                                label: t("group.delete"),
                                onClick: onDelete,
                            },
                        ]}
                        sheetCancelLabel={t("common.cancel")}
                        sheetTitle={t("common.edit")}
                    >
                        <ButtonText icon="Options" />
                    </ContextMenu>
                </OptionsColumn>
            </GroupItemWrapper>
            <ChildrenWrapper isOpen={isOpen} index={index}>
                {showChildren && children}
            </ChildrenWrapper>
            <DeleteGroupModal onConfirm={onConfirm} isLoading={isLoading} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};
