import { createSlice } from "@reduxjs/toolkit";
import { UserModel } from "../../models/UserModel";
import { userApi } from "../services/UserApi";

interface UserStateModel {
    user: UserModel;
}

export const initialState: UserStateModel = {
    user: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
            state.user = {
                ...action.payload,
                preferences: action.payload.preferences ?? [], // preferences can come as null, we need to initialize it with an empty array in state, to avoid errors during T&C flow
            };
        });
    },
});
