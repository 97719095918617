import { Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";
import { Section } from "../../../styles/Page.styles";

export const CardsSection = styled(Section)`
    overflow-y: auto;

    > :last-child {
        margin-bottom: ${Spacing.XS}px;
    }
`;

export const ScrollableStack = styled(Stack)`
    overflow-y: auto;
`;
