import { createSlice } from "@reduxjs/toolkit";
import JWT from "jwt-decode";
import { AccessTokenModel } from "../../models/TokenModel";
import { accessApi } from "../services/AccessApi";

interface AccessStateModel {
    accessToken: string;
    accessTokenDecoded: AccessTokenModel;
    isAccessExpired: boolean;
}

export const initialState: AccessStateModel = {
    accessToken: null,
    accessTokenDecoded: null,
    isAccessExpired: false,
};

export const accessSlice = createSlice({
    name: "access",
    initialState,
    reducers: {
        clearAccessToken: () => initialState,
        setAccessExpired(state) {
            state.isAccessExpired = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(accessApi.endpoints.getAccessToken.matchFulfilled, (state, { payload }) => {
                if (payload.token) {
                    state.accessToken = payload.token;
                    state.accessTokenDecoded = JWT<AccessTokenModel>(payload.token);
                }
            })
            .addMatcher(accessApi.endpoints.getAccessTokenBySignOnToken.matchFulfilled, (state, { payload }) => {
                if (payload.token) {
                    state.accessToken = payload.token;
                    state.accessTokenDecoded = JWT<AccessTokenModel>(payload.token);
                }
            })
            .addMatcher(accessApi.endpoints.refreshAccessToken.matchFulfilled, (state, { payload }) => {
                if (payload.token) {
                    state.accessToken = payload.token;
                    state.accessTokenDecoded = JWT<AccessTokenModel>(payload.token);
                }
            });
    },
});

export const accessActions = accessSlice.actions;
