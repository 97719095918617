import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetBrandingInfoQuery } from "../services/AppApi";
import { useLazyGetAuthorizationRecordsQuery } from "../services/AuthorizationApi";
import { useLazyGetAuthorizedNodesQuery } from "../services/OrganizationApi";
import { useAccessToken } from "./AccessHooks";
import { useUser } from "./UserHooks";
import AccessSelectors from "../selectors/AccessSelectors";
import AuthSelectors from "../selectors/AuthSelectors";
import OrganizationSelectors from "../selectors/OrganizationSelectors";

export const useAuthorizationRecords = () => {
    const userId = useSelector(AccessSelectors.selectUserId);
    const [getAuthRecords, { data: authRecords, isLoading, isError, isUninitialized }] = useLazyGetAuthorizationRecordsQuery();

    useEffect(() => {
        if (userId && isUninitialized) {
            getAuthRecords(userId);
        }
    }, [userId, isUninitialized, getAuthRecords]);

    return { authRecords, isUninitialized, isLoading, isError };
};

export const useAuthorizedNodes = () => {
    const locationsIds = useSelector(AuthSelectors.selectRecordsLocationsIds);
    const [getAuthNodes, { isLoading, isError, isUninitialized }] = useLazyGetAuthorizedNodesQuery();

    useEffect(() => {
        if (isUninitialized && locationsIds.length > 0) {
            getAuthNodes(locationsIds);
        }
    }, [isUninitialized, locationsIds, getAuthNodes]);

    return { isUninitialized, isLoading, isError };
};

export const useAuthorizationFlow = () => {
    const userId = useSelector(AccessSelectors.selectUserId);
    const nodes = useSelector(OrganizationSelectors.selectAuthorizedNodes);
    const isAccessExpired = useSelector(AccessSelectors.selectIsAccessExpired);
    const { accessToken, isLoading: isLoadingAccessToken, isError: isErrorAccessToken } = useAccessToken();
    const { user, isUninitialized: isUninitializedUser, isLoading: isLoadingUser, isError: isErrorUser } = useUser();
    const { isUninitialized: isUninitializedAuthLocations, isLoading: isLoadingAuthLocations, isError: isErrorAuthLocations } = useAuthorizedNodes();
    const {
        authRecords,
        isUninitialized: isUninitializedAuthRecords,
        isLoading: isLoadingAuthRecords,
        isError: isErrorAuthRecords,
    } = useAuthorizationRecords();
    const {
        isUninitialized: isUninitializedBrandingInfo,
        isLoading: isLoadingBrandingInfo,
        isError: isErrorBrandingInfo,
    } = useGetBrandingInfoQuery(userId, { skip: !userId, refetchOnMountOrArgChange: true });

    const hasLoaded = useMemo(() => {
        const hasLoadedAccessToken = !isLoadingAccessToken;
        const hasLoadedUser = !isUninitializedUser && !isLoadingUser;
        const hasLoadedAuthRecords = !isUninitializedAuthRecords && !isLoadingAuthRecords;
        const hasLoadedAuthorizedNodes = (!isUninitializedAuthLocations && !isLoadingAuthLocations) || (hasLoadedAuthRecords && authRecords.length === 0);
        const hasLoadedBrandingInfo = !isUninitializedBrandingInfo && !isLoadingBrandingInfo;
        return hasLoadedAccessToken && hasLoadedUser && hasLoadedAuthRecords && hasLoadedAuthorizedNodes && hasLoadedBrandingInfo;
    }, [
        authRecords,
        isLoadingAccessToken,
        isUninitializedUser,
        isLoadingUser,
        isUninitializedAuthRecords,
        isLoadingAuthRecords,
        isUninitializedAuthLocations,
        isLoadingAuthLocations,
        isUninitializedBrandingInfo,
        isLoadingBrandingInfo,
    ]);

    const isError = useMemo(() => {
        return isErrorAccessToken || isErrorUser || isErrorAuthRecords || isErrorAuthLocations || isErrorBrandingInfo;
    }, [isErrorAccessToken, isErrorUser, isErrorAuthRecords, isErrorAuthLocations, isErrorBrandingInfo]);

    const isAccessDenied = useMemo(() => {
        if (hasLoaded) {
            const isUserInvalid = !user;
            const isAccessTokenInvalid = !accessToken;
            const areAuthRecordsInvalid = !authRecords || authRecords.length === 0;
            const areAuthNodesInvalid = !areAuthRecordsInvalid && nodes.length === 0;
            return isAccessTokenInvalid || isUserInvalid || areAuthRecordsInvalid || areAuthNodesInvalid;
        }
        return false;
    }, [hasLoaded, accessToken, user, authRecords, nodes]);

    return {
        isLoading: !hasLoaded,
        isError,
        isAccessDenied,
        isAccessExpired,
    };
};
