import React, { createContext, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { IntercomContextModel, IntercomPayload, IntercomTopic } from "../models/IntercomModel";
import AppSelectors from "../store/selectors/AppSelectors";

export const IntercomContext = createContext<IntercomContextModel>({} as IntercomContextModel);

export const IntercomProvider: React.FC = ({ children }) => {
    const hubApp = useSelector(AppSelectors.selectHub);

    const receivedMessage = () => {
        // for the time being we don't have any US where we are receiving messages from Hubs
    };

    const postMessage = useCallback(
        (topic: IntercomTopic, message: string) => {
            const payload: IntercomPayload = {
                topic,
                message,
                timestamp: new Date(),
            };
            window.parent.postMessage(payload, "*");
        },
        [hubApp]
    );

    useEffect(() => {
        window.addEventListener("message", receivedMessage);
        return () => window.removeEventListener("message", receivedMessage);
    }, []);

    return <IntercomContext.Provider value={{ postMessage }}>{children}</IntercomContext.Provider>;
};
