import React from "react";
import { LoadingIndicator } from "@secuis/ccp-react-components";
import { Wrapper } from "./SplashScreen.styles";

const SplashScreen: React.FC = () => (
    <Wrapper data-testid="splash-screen">
        <LoadingIndicator size="L" />
    </Wrapper>
);

export default SplashScreen;
