import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, ButtonText, Dropdown, DropdownCheckbox, Icon, Input, Stack, Text, ToasterContext } from "@secuis/ccp-react-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import GroupsSelectors from "../../store/selectors/GroupsSelectors";
import { GroupEditorType, GroupTreeModel } from "../../models/GroupModel";
import { useLazyGetGroupsQuery } from "../../store/services/OrganizationApi";
import OrganizationEditorSelectors from "../../store/selectors/OrganizationEditorSelectors";
import { EditGroupLocations } from "./EditGroupLocations";
import { getParentsPath, getIds, getChildren, getGroupsTreeByIds, checkParentMaxNestingLevel, checkChildMaxNestingLevel } from "../../helpers/GroupsHelpers";
import { MAXIMUM_NESTING_LEVEL } from "../GroupsSteps/CreateGroups";
import { ParentDropdownWrapper } from "./GroupsTable.styles";
import { ErrorData } from "../../store/services/Models";
import { useEditGroupBatch } from "../../hooks/EditGroupHooks";
import { PEOPLE_ONBOARDED_ERROR, useDeleteGroup } from "../../hooks/DeleteGroupHooks";

interface EditGroupModalProps {
    isOpen: boolean;
    closeModal: () => void;
    group: GroupTreeModel;
}

export const EditGroupModal = ({ isOpen, closeModal, group }: EditGroupModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [groupEditor, setGroupEditor] = useState<GroupEditorType>(null);
    const [getGroups] = useLazyGetGroupsQuery();
    const { deleteGroup, isLoading: isDeleteLoading } = useDeleteGroup();
    const { editGroup, isLoading: isEditLoading } = useEditGroupBatch(groupEditor, group);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [hasPeopleOnboarded, setHasPeopleOnboarded] = useState(false);
    const [hasUnknownError, setHasUnknownError] = useState(false);
    const [errors, setErrors] = useState<{ [k: string]: string }>(null);
    const groups = useSelector(GroupsSelectors.selectGroups);
    const groupsTree = useSelector(GroupsSelectors.selectGroupsTree);
    const selectedClientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const { addToast } = useContext(ToasterContext);

    useEffect(() => {
        if (group) {
            setGroupEditor({ ...group, locationsToDelete: [], childrenToRemove: [], childrenToAdd: [] });
        }
    }, [group]);

    useEffect(() => {
        if (!isOpen) {
            setGroupEditor(null);
            setShowConfirmDelete(false);
            setErrors(null);
        }
        clearErrors();
    }, [isOpen]);

    const onChangeName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const { value } = event.target;
        setGroupEditor({
            ...groupEditor,
            name: value,
        });
        setErrors(null);
        clearErrors();
    };

    const onChangeParent = (parentId: string) => {
        setGroupEditor({
            ...groupEditor,
            parentId,
        });
        clearErrors();
    };

    const onChangeSubgroups = (value: string[]) => {
        setGroupEditor({
            ...groupEditor,
            children: getGroupsTreeByIds(groupsTree, value),
            childrenToAdd: value.filter((id) => !group.children.some((child) => child.id === id)),
            childrenToRemove: group.children.filter((c) => !value.includes(c.id)).map((c) => c.id),
        });
        clearErrors();
    };

    const onRemoveLocation = (value: string) => {
        const newLocations = groupEditor.locationIds.filter((locationId) => locationId !== value);
        setGroupEditor({
            ...groupEditor,
            locationIds: newLocations,
            locationsToDelete: [...groupEditor.locationsToDelete, value],
        });
        clearErrors();
    };

    const getErrors = useCallback(
        (editor: GroupEditorType): { [k: string]: string } => {
            if (!editor.name) {
                return { name: t("group.editNameError") };
            }
            return null;
        },
        [t]
    );

    const asyncSubmit = useCallback(async () => {
        const errors = getErrors(groupEditor);
        if (errors) {
            setErrors(errors);
        } else {
            try {
                await editGroup();
                closeModal();
                addToast({ title: t("common.changesSaved"), preserveOnHover: false, type: "success" });
                getGroups(selectedClientId);
            } catch (err) {
                if (err?.["data"] || err?.["error"]) {
                    setHasUnknownError(true);
                }
                if (err?.["status"] === 401) {
                    closeModal();
                }
            }
        }
    }, [addToast, editGroup, getGroups, selectedClientId, t, closeModal, getErrors, groupEditor]);

    const clearErrors = () => {
        setHasUnknownError(false);
        setHasPeopleOnboarded(false);
    };

    const onSubmit = () => {
        void asyncSubmit();
    };

    const onConfirm = useCallback(async () => {
        try {
            const idsToRemove = getIds(group, group.id).reverse();
            await deleteGroup(idsToRemove);
            closeModal();
            addToast({ title: t("groups.deleteGroup.successMessage", { count: idsToRemove.length }), preserveOnHover: false, type: "success" });
            getGroups(selectedClientId);
        } catch (error: any) {
            if (error?.message === PEOPLE_ONBOARDED_ERROR) {
                setHasPeopleOnboarded(true);
            }

            const { status } = error as ErrorData;
            if (status === 401) {
                closeModal();
            }
        }
    }, [addToast, deleteGroup, getGroups, group, selectedClientId, t, closeModal]);

    const onDelete = () => {
        clearErrors();
        if (group.children.length) {
            setShowConfirmDelete(true);
        } else {
            onConfirm();
        }
    };

    const groupsOptions = useMemo(() => {
        return group
            ? groups
                  .map((g) => ({ title: g.name, value: g.id }))
                  .filter((g) => g.value !== group.id)
                  .sort((a, b) => a.title.localeCompare(b.title))
            : [];
    }, [groups, group]);

    const parentOptions = useMemo(() => {
        const options = groupEditor
            ? groupsOptions.filter((option) => {
                  const children = getChildren(groupEditor);
                  const isChild = children.some((c) => c.id === option.value);
                  return !isChild && !checkParentMaxNestingLevel(option.value, groupEditor, groupsTree, MAXIMUM_NESTING_LEVEL);
              })
            : [];
        return [{ title: t("groups.editGroup.emptyParent"), value: selectedClientId }, ...options];
    }, [selectedClientId, groupsOptions, groupEditor, t, groupsTree]);

    const childrenOptions = useMemo(() => {
        const parentsIds = getParentsPath(groups, groupEditor);
        return groupEditor
            ? groupsOptions.filter((option) => {
                  const isParent = parentsIds.includes(option.value);
                  return !isParent && !checkChildMaxNestingLevel(option.value, groupEditor, groupsTree, MAXIMUM_NESTING_LEVEL);
              })
            : [];
    }, [groupsOptions, groupEditor, groups, groupsTree]);
    const hasSubgroupDisabled = groupEditor?.headPosition >= MAXIMUM_NESTING_LEVEL;
    const isLoading = isEditLoading || isDeleteLoading;

    return (
        <Modal
            actions={
                showConfirmDelete
                    ? [
                          {
                              id: "back",
                              children: t("common.back"),
                              onClick: () => {
                                  clearErrors();
                                  setShowConfirmDelete(false);
                              },
                              loading: isLoading,
                              isPrimary: false,
                          },
                          {
                              id: "delete",
                              children: t("common.deleteAll"),
                              onClick: () => {
                                  void onConfirm();
                              },
                              loading: isLoading,
                          },
                      ]
                    : [
                          {
                              id: "save",
                              children: t("common.saveChanges"),
                              onClick: onSubmit,
                              loading: isLoading,
                          },
                      ]
            }
            isOpen={isOpen}
            title={showConfirmDelete ? t("groups.deleteGroup.confirmTitle") : t("group.edit")}
            onClose={closeModal}
            headerChildren={
                !showConfirmDelete && (
                    <ButtonText disabled={isLoading} icon="Delete" onClick={onDelete}>
                        {t("group.delete")}
                    </ButtonText>
                )
            }
            footerError={hasUnknownError && t("editGroup.unknownError")}
        >
            {hasPeopleOnboarded && (
                <Stack alignItems="center" gap="XS" marginVertical="M">
                    <Icon variant="Error" color="error" />
                    <Text color="error">
                        {showConfirmDelete ? t("editGroup.deleteOnboardedSubgroupsError.message") : t("editGroup.deleteOnboardedError.message")}
                    </Text>
                </Stack>
            )}
            {group && groupEditor && !showConfirmDelete && (
                <Stack gap="S" direction="column" marginTop="M">
                    <Input
                        width="100%"
                        disabled={isLoading}
                        label={t("groups.edit.name")}
                        placeholder={t("groups.createGroupsModal.input.value")}
                        value={groupEditor.name}
                        onChange={onChangeName}
                        invalid={!!errors?.name}
                        error={errors?.name}
                    />
                    <Text color="neutral" micro uppercase>
                        {t("groups.edit.createHierarchy")}
                    </Text>
                    <Stack gap="M">
                        <Dropdown
                            label={t("groups.edit.parentGroup")}
                            placeholder={t("group.editParentPlaceholder")}
                            maxHeight={300}
                            onChange={onChangeParent}
                            options={parentOptions}
                            sheetCancelLabel={t("common.cancel")}
                            value={groupEditor.parentId === selectedClientId ? null : groupEditor.parentId}
                            disabled={isLoading}
                        />
                        <ParentDropdownWrapper>
                            <DropdownCheckbox
                                label={t("groups.edit.subgroups")}
                                maxHeight={300}
                                placeholder={t("groups.edit.subgoupsPlaceholder")}
                                onChange={onChangeSubgroups}
                                options={childrenOptions}
                                sheetCancelLabel={t("common.cancel")}
                                values={groupEditor.children.map((c) => c.id)}
                                disabled={isLoading || hasSubgroupDisabled}
                            />
                            {hasSubgroupDisabled && (
                                <Box marginVertical="XXS" marginHorizontal="S">
                                    <Text color="secondary" micro>
                                        {t("groups.edit.hierarchyLimitMessage", { count: MAXIMUM_NESTING_LEVEL + 1 })}
                                    </Text>
                                </Box>
                            )}
                        </ParentDropdownWrapper>
                    </Stack>
                    <EditGroupLocations groupEditor={groupEditor} onRemoveLocation={onRemoveLocation} />
                </Stack>
            )}
            {group && groupEditor && showConfirmDelete && <Text>{t("groups.deleteGroup.modalBodyText")}</Text>}
        </Modal>
    );
};
