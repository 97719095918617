import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";

// this selector would always return a new array (a new reference) since it does calculations on state (filter function)
// therefore it would cause infinite rerenders (as reference changes) unless we will memoize it with createSelector
// read more: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
const selectRecords = createSelector([({ auth: { records } }: RootState) => records], (records) => {
    return records.filter(({ isDeleted }) => !isDeleted);
});

const selectRecordsLocationsIds = createSelector([selectRecords], (records) => {
    return Array.from(new Set(records.map(({ locationId }) => locationId)));
});

export default {
    selectRecords,
    selectRecordsLocationsIds,
};
