import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonText, Input } from "@secuis/ccp-react-components";
import { InputWrapper } from "./CreateGroupInput.styles";

interface GroupInputProps {
    errors: string[];
    showDelete: boolean;
    value: string;
    disabled?: boolean;
    onDelete: () => void;
    onInputChange: (value: string) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CreateGroupInput: React.FC<GroupInputProps> = ({ errors = [], showDelete, value, onDelete, onInputChange, onKeyDown, disabled = false }) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event.target.value);
    };

    return (
        <InputWrapper gap="XS">
            <Input
                width="100%"
                disabled={disabled}
                error={errors.join(", ")}
                label={t("groups.createGroupsModal.input.label")}
                placeholder={t("groups.createGroupsModal.input.value")}
                invalid={!!errors.length}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyDown}
            />

            {showDelete && <ButtonText disabled={disabled} iconSize="M" icon="Delete" onClick={onDelete} />}
        </InputWrapper>
    );
};

export default CreateGroupInput;
