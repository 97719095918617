import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { i18nextInit } from "./i18next";
import { syncI18nCultures } from "./i18nextSync";
import { IntercomProvider } from "./components/Intercom";
import { useAuthorizationFlow } from "./store/hooks/AuthHooks";
import { useAppErrors, usePopulateStateFromQueryString } from "./store/hooks/AppHooks";
import SplashScreen from "./components/SplashScreen";
import NoAccessPage from "./pages/NoAccessPage";
import FailurePage from "./pages/FailurePage";
import AppLayout from "./components/AppLayout";

i18nextInit();
syncI18nCultures();

let App: React.FC = () => {
    usePopulateStateFromQueryString();
    const { isLoading, isError, isAccessDenied, isAccessExpired } = useAuthorizationFlow();
    useAppErrors(!isLoading);

    if (isError) {
        return <FailurePage />;
    }

    if (isAccessDenied) {
        return <NoAccessPage />;
    }

    if (isLoading) {
        return <SplashScreen />;
    }

    return <IntercomProvider>{isAccessExpired ? <NoAccessPage /> : <AppLayout />}</IntercomProvider>;
};

if (process.env.REACT_APP_RUN_STANDALONE === "true") {
    App = withAuthenticationRequired(App, {
        returnTo: () => window.location.pathname,
    });
}

export default App;
