import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { RootState } from "../../Store";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const { accessToken } = state.access;

        if (accessToken && !headers.has("Authorization")) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
        headers.set("Content-Type", "application/json");
        headers.set("Accept", "application/json");

        return headers;
    },
});

export default baseQuery;
