import i18next from "i18next";
import { NodeLabel, NodeDTO, NodeModel } from "../models/OrganizationNodeModel";
import { getValueFromProperty } from "./PropertiesValueHelper";

export const mapToOrganizationNode = (dto: NodeDTO, addressFields: string[] = ["address", "postCode", "postAddress"]): NodeModel => {
    const node: NodeModel = {
        id: dto.id,
        label: dto.label,
        labelDisplay: i18next.exists(`groups.clients.labels.${dto.label}`) ? i18next.t(`groups.clients.labels.${dto.label}`) : dto.label,
        hasChildren: dto.hasChildren,
        name: getValueFromProperty(dto, "name"),
        countryCode: getValueFromProperty(dto, "countryCode")?.toUpperCase(),
        timestamp: getValueFromProperty(dto, "timestamp"),
    };

    const isDeleted = getValueFromProperty(dto, "isDeleted");
    if (isDeleted === "1" || isDeleted.toLowerCase() === "true") {
        node.isDeleted = true;
    }

    node.address = addressFields
        .map((field) => {
            if (field === "postCode" && dto.label === NodeLabel.SiteObject) {
                return getValueFromProperty(dto, "postalCode");
            }
            return getValueFromProperty(dto, field);
        })
        .filter((str) => str)
        .join(", ");

    switch (dto.label) {
        case NodeLabel.Customer:
            node.number = getValueFromProperty(dto, "customerNo");
            break;
        case NodeLabel.CustomerObject:
            node.number = getValueFromProperty(dto, "custObjNo");
            break;
        case NodeLabel.SiteObject:
            node.number = getValueFromProperty(dto, "sourceId");
            break;
    }

    return node;
};
