import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useLazyGetAccessTokenBySignOnTokenQuery, useLazyGetAccessTokenQuery } from "../services/AccessApi";
import AppSelectors from "../selectors/AppSelectors";

export const useAccessToken = () => {
    const [getAccessTokenBySignOnToken, { data: accessToken, isLoading, isError }] = useLazyGetAccessTokenBySignOnTokenQuery();
    const [getAccessTokenForDevelopment, { data: accessTokenDev, isLoading: isLoadingDev, isError: isErrorDev }] = useLazyGetAccessTokenQuery();
    const { error: loginError, getIdTokenClaims } = useAuth0();
    const [isUninitialized, setIsUninitialized] = useState(true);
    const [idToken, setIdToken] = useState(null);
    const signOnToken = useSelector(AppSelectors.selectSignOnToken);

    useEffect(() => {
        const readAndSetClaims = async () => {
            const idTokenClaims = await getIdTokenClaims();
            if (idTokenClaims) {
                setIdToken(idTokenClaims.__raw);
            }
        };
        if (!loginError && process.env.REACT_APP_RUN_STANDALONE === "true") {
            readAndSetClaims();
        }
    }, [loginError, getIdTokenClaims]);

    useEffect(() => {
        if (idToken) {
            setIsUninitialized(false);
            getAccessTokenForDevelopment(idToken);
        }
    }, [idToken, getAccessTokenForDevelopment]);

    useEffect(() => {
        if (signOnToken) {
            setIsUninitialized(false);
            getAccessTokenBySignOnToken(signOnToken);
        }
    }, [signOnToken, getAccessTokenBySignOnToken]);

    return {
        accessToken: signOnToken ? accessToken : accessTokenDev,
        isLoading: signOnToken ? isLoading : isLoadingDev,
        isError: signOnToken ? isError : isErrorDev,
        isUninitialized,
    };
};
