import React, { useContext } from "react";
import { Text, Modal } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageCentered from "../components/PageCentered";
import { IntercomTopic } from "../models/IntercomModel";
import { IntercomContext } from "../components/Intercom";
import AccessSelectors from "../store/selectors/AccessSelectors";
import AppSelectors from "../store/selectors/AppSelectors";

const NoAccessPage: React.FC = () => {
    const { t } = useTranslation();
    const { postMessage } = useContext(IntercomContext);
    const { applicationName } = useSelector(AppSelectors.selectBrandingInfo);
    const isAccessExpired = useSelector(AccessSelectors.selectIsAccessExpired);

    const onClickHandle = () => postMessage(IntercomTopic.EMBEDDED_APP_ACCESS_EXPIRED, "");

    return (
        <PageCentered title={t("noAccessPage.title")}>
            <Text centered>{t("noAccessPage.info1")}</Text>
            <Text centered>{t("noAccessPage.info2", { applicationName })}</Text>
            <Modal
                actions={[
                    {
                        onClick: onClickHandle,
                        children: t("common.reload"),
                    },
                ]}
                isOpen={isAccessExpired}
                size="S"
                title={t("noAccessPage.timedOutModal.title")}
                subtitle={t("noAccessPage.timedOutModal.subtitle")}
                onClose={onClickHandle}
            />
        </PageCentered>
    );
};

export default NoAccessPage;
