import React from "react";
import { Badge, RadioButton, Stack, Text } from "@secuis/ccp-react-components";
import { CardGrid, BadgeCell } from "./ClientCard.styles";

interface ClientCardProps {
    id: string;
    badgeLabel: string;
    title: string;
    subtitle?: string;
    selected?: boolean;
    onSelect: () => void;
}

const ClientCard: React.FC<ClientCardProps> = ({ id, badgeLabel, title, subtitle, selected, onSelect }) => {
    return (
        <CardGrid selected={selected} onClick={onSelect}>
            <BadgeCell>
                <Badge label={badgeLabel} />
            </BadgeCell>
            <RadioButton readOnly id={`card__radio-button-${id}`} name={title} checked={selected} onClick={onSelect}></RadioButton>
            <Stack direction="column">
                <Text bold>{title}</Text>
                {subtitle && (
                    <Text micro color="neutral">
                        {subtitle}
                    </Text>
                )}
            </Stack>
        </CardGrid>
    );
};

export default ClientCard;
