export interface RoleModel {
    id: string;
    roleName: string;
    displayName: string;
    createdTime: string;
    isDeleted: boolean;
    isInternal?: boolean;
    serviceVerbs?: string[];
    verbs?: string[];
    allowedLocationLabels?: string[];
    // custom properties
    title?: string;
    description?: string;
}

export enum RoleName {
    OrganizationAdmin = "OrganizationAdmin",
}
