import { Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

interface DataCellProps {
    isSelected: boolean;
}

export const DataCell = styled.div<DataCellProps>`
    background-color: ${Palette.Navy750};
    padding: ${Spacing.S}px ${Spacing.M}px;
    border-top: ${({ isSelected }) => `2px solid ${isSelected ? Palette.Navy300 : "transparent"}`};
    border-bottom: ${({ isSelected }) => `2px solid ${isSelected ? Palette.Navy300 : "transparent"}`};
    margin-bottom: ${Spacing.XS}px;
    display: flex;
    align-items: center;
`;

export const SiteCell = styled(DataCell)`
    border-left: ${({ isSelected }) => `2px solid ${isSelected ? Palette.Navy300 : "transparent"}`};
    border-radius: 2px 0 0 2px;

    & label {
        white-space: normal;
    }
`;

export const StatusCell = styled(DataCell)`
    flex-wrap: wrap;
    gap: ${Spacing.XS}px;
    border-right: ${({ isSelected }) => `2px solid ${isSelected ? Palette.Navy300 : "transparent"}`};
    border-radius: 0 2px 2px 0;
`;
