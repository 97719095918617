import React from "react";
import { Text, Title } from "@secuis/ccp-react-components";
import { Content, TitleWrapper, Wrapper } from "./PageCentered.styles";

interface PageProps {
    title: string;
    message?: string;
}

const PageCentered: React.FC<PageProps> = ({ children, title, message }) => (
    <Wrapper>
        <Content>
            <TitleWrapper>
                <Title centered>{title}</Title>
            </TitleWrapper>
            {!!message && <Text centered>{message}</Text>}
            {children}
        </Content>
    </Wrapper>
);

export default PageCentered;
