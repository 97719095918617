import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ButtonText, ContextMenu, EmptyState, LoadingIndicator, Search, Stack, Text } from "@secuis/ccp-react-components";
import { HeaderCell, SearchWrapper, Table } from "./LocationsTable.styles";
import { organizationEditorActions } from "../../store/reducers/OrganizationEditorSlice";
import AddSitesToGroupButton from "../AddSitesToGroupButton";
import AddLocationsModal from "../AddLocationsModal";
import AppSelectors from "../../store/selectors/AppSelectors";
import GroupsSelectors from "../../store/selectors/GroupsSelectors";
import LocationsSelectors from "../../store/selectors/LocationsSelectors";
import { LocationsTableRow } from "./LocationsTableRow";
import { SortButton } from "./SortButton";
import { SortOrderType, StatusSort } from "../../helpers/LocationsHelpers";
import { useSortedLocations } from "../../store/hooks/LocationsHooks";
import { locationsActions } from "../../store/reducers/LocationsSlice";
import { CenteredContent } from "../../pages/CenteredContent";

export const LocationsTable = (): JSX.Element => {
    const isLoading = useSelector(LocationsSelectors.selectIsLoading);
    const isGroupsLoading = useSelector(GroupsSelectors.selectIsLoading);
    const locations = useSelector(LocationsSelectors.selectLocations);
    const searchStr = useSelector(LocationsSelectors.selectSearchStr);
    const sortSettings = useSelector(LocationsSelectors.selectSortSettings);
    const { applicationName } = useSelector(AppSelectors.selectBrandingInfo);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [showAddSitesToGroupModal, setShowAddSitesToGroupModal] = useState(false);
    const sortedLocations = useSortedLocations();

    const columnHeaders = [
        { label: t("locationsTable.header.siteName"), sortBy: "name" },
        { label: t("locationsTable.header.address"), sortBy: "address" },
        { label: t("locationsTable.header.country"), sortBy: "country" },
        { label: t("locationsTable.header.siteId"), sortBy: "number" },
        { label: t("locationsTable.header.status"), sortBy: "groupsSort" },
    ];
    const onSelect = (id: string) => {
        setSelectedIds((prevState: string[]) => (prevState.includes(id) ? prevState.filter((siteId) => siteId !== id) : [...prevState, id]));
    };

    const setSorting = (sortBy: string) => {
        if (sortSettings.sortBy === sortBy) {
            dispatch(locationsActions.changeSortSettings({ sortBy, order: sortSettings.order === "asc" ? "desc" : "asc" }));
        } else {
            dispatch(locationsActions.changeSortSettings({ sortBy, order: "asc" }));
        }
    };

    useEffect(() => {
        dispatch(organizationEditorActions.setSelectedLocationIds(selectedIds));
    }, [dispatch, selectedIds]);

    useEffect(() => {
        setSelectedIds([]);
        return () => {
            clearSearch();
        };
    }, [isLoading, isGroupsLoading]);

    const showTable = !(isLoading || isGroupsLoading);

    const handleSearchInputChange = (event) => {
        if (!event.target.value) {
            clearSearch();
        }
        setInputValue(event.target.value);
    };

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(locationsActions.changeSearchStr(inputValue));
        }
    };

    const clearSearch = () => {
        dispatch(locationsActions.changeSearchStr(""));
        setInputValue("");
    };

    const isAllSelected = sortedLocations.every((result) => selectedIds.includes(result.id));

    const handleSelectAll = () => {
        const resultsIds = sortedLocations.map((r) => r.id);

        if (isAllSelected) {
            setSelectedIds(selectedIds.filter((id) => !resultsIds.includes(id)));
        } else {
            setSelectedIds([...selectedIds, ...resultsIds]);
        }
    };

    const isStatusSorting = sortSettings.sortBy === "groupsSort";

    return (
        <>
            <Stack gap="S" justifyContent="space-between">
                <SearchWrapper>
                    <Search
                        key={`${showAddSitesToGroupModal}`}
                        placeholder={t("locationsTable.searchLocation")}
                        inputValue={inputValue}
                        onChange={handleSearchInputChange}
                        onKeyDown={handleEnter}
                        onClear={clearSearch}
                    />
                </SearchWrapper>
                <AddSitesToGroupButton onClick={() => setShowAddSitesToGroupModal(true)} />
            </Stack>
            {searchStr && !!sortedLocations.length && (
                <Stack marginTop="XS" marginBottom="XS" paddingTop="XS" paddingBottom="XS" paddingLeft="M" gap="L">
                    <Text>{t("locationsTable.searchResults", { count: sortedLocations.length })}</Text>
                    <ButtonText color="accent" onClick={handleSelectAll}>
                        {isAllSelected ? t("common.deselectAll") : t("common.selectAll")}
                    </ButtonText>
                </Stack>
            )}
            {(isLoading || isGroupsLoading) && (
                <CenteredContent>
                    <LoadingIndicator size="L" />
                </CenteredContent>
            )}
            {showTable && (
                <>
                    {sortedLocations.length > 0 ? (
                        <Table>
                            {columnHeaders.map((header) => (
                                <HeaderCell
                                    marginBottom="XS"
                                    marginTop="XS"
                                    paddingLeft="M"
                                    key={header.label}
                                    onClick={header.sortBy === "groupsSort" ? undefined : () => setSorting(header.sortBy)}
                                >
                                    {header.sortBy === "groupsSort" ? (
                                        <ContextMenu
                                            sheetCancelLabel={t("common.cancel")}
                                            align="right"
                                            options={Object.keys(StatusSort).map((k) => ({
                                                label: t(`locations.sort.${k}`),
                                                onClick: () => {
                                                    dispatch(locationsActions.changeSortSettings({ sortBy: "groupsSort", order: k as SortOrderType }));
                                                },
                                            }))}
                                        >
                                            <ButtonText
                                                small
                                                color={isStatusSorting ? "accent" : "primary"}
                                                icon={sortSettings.order === StatusSort.groupDesc ? "SortArrowUp" : "SortArrowDown"}
                                            >
                                                {isStatusSorting ? t(`locations.sortOrder.${sortSettings.order}`) : header.label}
                                            </ButtonText>
                                        </ContextMenu>
                                    ) : (
                                        <SortButton
                                            label={header.label}
                                            isActive={sortSettings.sortBy === header.sortBy}
                                            sortAsc={sortSettings.order === "asc"}
                                        />
                                    )}
                                </HeaderCell>
                            ))}
                            {sortedLocations.map((location) => {
                                const isSelected = selectedIds.includes(location.id);

                                return <LocationsTableRow key={location.id} location={location} isSelected={isSelected} onSelect={onSelect} />;
                            })}
                        </Table>
                    ) : (
                        <>
                            {!locations.length && (
                                <CenteredContent>
                                    <EmptyState
                                        icon="Building"
                                        title={t("groups.locationsTable.empty.title")}
                                        subtitle={t("groups.locationsTable.empty.subTitle", { applicationName })}
                                    />
                                </CenteredContent>
                            )}
                            {!!locations.length && !sortedLocations.length && (
                                <CenteredContent>
                                    <EmptyState
                                        icon="Search"
                                        title={t("locationsTable.noSearchResults.title")}
                                        subtitle={t("locationsTable.noSearchResults.subtitle")}
                                    />
                                </CenteredContent>
                            )}
                        </>
                    )}
                </>
            )}
            <AddLocationsModal isOpen={showAddSitesToGroupModal} onClose={() => setShowAddSitesToGroupModal(false)} />
        </>
    );
};
