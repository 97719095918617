import React from "react";
import { Stack, Text, ChipFilter } from "@secuis/ccp-react-components";
import { NodeModel } from "../models/OrganizationNodeModel";

export interface Chip {
    id: string;
    label: string;
    disabled?: boolean;
    onRemove: () => void;
}
interface ChipListProps {
    label: string;
    locations: NodeModel[];
    disabled?: boolean;
    onRemove: (id: string) => void;
}

const ChipList: React.FC<ChipListProps> = ({ disabled, label, locations, onRemove }) => {
    const chips = locations.map((l) => ({
        id: l.id,
        label: l.name,
        disabled: locations.length === 1 || disabled,
        onRemove: () => onRemove(l.id),
    }));

    return (
        <Stack direction="column" gap="S">
            <Text micro bold color="secondary" uppercase>
                {label} ({chips.length})
            </Text>
            <Stack gap="XS" flexWrap="wrap">
                {chips.map((chip) => (
                    <ChipFilter key={chip.id} label={chip.label} disabled={chip.disabled} onRemove={chip.onRemove} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ChipList;
