import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal, Stack, Text, ToasterContext } from "@secuis/ccp-react-components";
import { useGroupsEditor } from "../hooks/EditGroupHooks";
import CreateGroupInputList from "./CreateGroupInputList";
import { useCreateGroupsMutation, useLazyGetGroupsQuery } from "../store/services/OrganizationApi";
import { useSelector } from "react-redux";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";

interface CreateGroupsModalProps {
    isOpen: boolean;
    onClose: () => void;
    parentId?: string;
}

const CreateGroupsModal: React.FC<CreateGroupsModalProps> = ({ isOpen, onClose, parentId }) => {
    const { t } = useTranslation();
    const groupsEditor = useGroupsEditor(new Map([[1, { value: "", errors: [] }]]));
    const [error, setErrors] = useState<string>(null);
    const [createGroups, { isLoading }] = useCreateGroupsMutation();
    const selectedClientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const { addToast } = useContext(ToasterContext);
    const [getGroups] = useLazyGetGroupsQuery();
    const closeModal = () => {
        onClose();
        groupsEditor.resetEditors();
        setErrors(null);
    };

    const onCreateGroups = async () => {
        if (groupsEditor.validateGroups(groupsEditor.validationFunctions.noEmptyValueValidator)) {
            const groups = [...groupsEditor.editors.values()];
            const results = await createGroups(groups.map((g) => ({ name: g.value, locationIds: [], parentId: parentId || selectedClientId }))).unwrap();
            const errors = results.filter((r) => r.error);
            const hasAccessDenied = results.some((r) => r.error && r.error.status === 401);

            if (hasAccessDenied) {
                addToast({ title: t("common.accessDenied"), type: "high" });
                return;
            }
            if (errors.length !== results.length) {
                getGroups(selectedClientId);
            }
            if (errors.length) {
                results.forEach((result, index) => {
                    if (!result.error) {
                        groupsEditor.onDeleteGroup(index + 1);
                    }
                });
                setErrors(t("error.createGroup.unknownError"));
                return;
            }
            closeModal();
            addToast({ title: t("groups.addGroups.successMessage", { count: groupsEditor.editors.size }), preserveOnHover: false, type: "success" });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            size="L"
            title={parentId ? t("groups.createSubGroupsModal.title") : t("groups.createGroupsModal.title")}
            subtitle={parentId ? t("groups.createSubGroupsModal.subtitle") : t("groups.createGroupsModal.subtitle")}
            onClose={closeModal}
            actions={[
                {
                    children: t("groups.createGroupsModal.submitButton"),
                    onClick: () => {
                        void onCreateGroups();
                    },
                    loading: isLoading,
                },
            ]}
        >
            <CreateGroupInputList
                addGroupBtnLabel={parentId ? t("groups.createSubGroupsModal.addAnotherSubGroupButton") : t("groups.createGroupsModal.addAnotherGroupButton")}
                gap="S"
                groupsEditor={groupsEditor}
            />
            {error && (
                <Stack alignItems="center" marginTop="S" gap="XS">
                    <Icon variant="Error" color="error" />
                    <Text color="error">{error}</Text>
                </Stack>
            )}
        </Modal>
    );
};

export default CreateGroupsModal;
