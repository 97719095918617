import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Stack, Dropdown, Box, Text } from "@secuis/ccp-react-components";
import { useGroupsEditor } from "../hooks/EditGroupHooks";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";
import { useLazyGetGroupsQuery } from "../store/services/OrganizationApi";
import GroupsSelectors from "../store/selectors/GroupsSelectors";
import { NodeModel } from "../models/OrganizationNodeModel";
import CreateGroupInputList from "./CreateGroupInputList";
import ChipList from "./ChipList";
import { Modal } from "./Modal";
import { useAddLocations } from "../hooks/AddLocationsHooks";

interface AddLocationsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const AddLocationsModal: React.FC<AddLocationsModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const clientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const groups = useSelector(GroupsSelectors.selectGroups);
    const selectedLocations = useSelector(OrganizationEditorSelectors.selectSortedSelectedLocations);
    const groupsEditor = useGroupsEditor(new Map());

    const [selectedGroupId, setSelectedGroupId] = useState<string>(null);
    const [modalError, setModalError] = useState<string>(null);
    const [groupDropdownError, setGroupDropdownError] = useState(null);
    const [modalSelectedLocations, setModalSelectedLocations] = useState<NodeModel[]>([]);

    const [getGroups] = useLazyGetGroupsQuery();
    const { addLocations, isInProgress } = useAddLocations(groupsEditor, modalSelectedLocations, selectedGroupId);

    useEffect(() => {
        setModalSelectedLocations(selectedLocations);
    }, [selectedLocations]);

    const groupsOptions = useMemo(() => {
        return groups.map((g) => ({
            title: g.name,
            value: g.id,
        }));
    }, [groups]);

    const dropdownPlaceholder = useMemo(() => {
        if (groups.length) {
            return t("groups.addLocationsModal.dropdown.placeholder");
        }
        return t("groups.addLocationsModal.dropdown.noGroupsPlaceholder");
    }, [groups, t]);

    const resetState = () => {
        groupsEditor.resetEditors();
        setGroupDropdownError(null);
        setSelectedGroupId(null);
        setModalError(null);
        setModalSelectedLocations(selectedLocations);
    };

    const closeModal = () => {
        onClose();
        resetState();
    };

    const onSubmit = async () => {
        setModalError(null);
        if (!groupsEditor.validateGroups(groupsEditor.validationFunctions.noEmptyValueValidator)) {
            return;
        }
        if (groupsOptions.length && !groupsEditor.editors.size) {
            if (!selectedGroupId) {
                setGroupDropdownError(t("groups.validationErrors.chooseGroup"));
                return;
            }
        }
        try {
            await addLocations();
        } catch (e: unknown) {
            setModalError(e["message"]);
            return;
        }
        getGroups(clientId);
        closeModal();
    };

    const onGroupSelection = (id: string): void => {
        setSelectedGroupId(id);
        setGroupDropdownError(null);
    };

    const onRemoveLocation = (id: string): void => {
        setModalSelectedLocations((chips) => chips.filter((l) => l.id != id));
    };

    const onNewGroupInputAdd = () => {
        setGroupDropdownError(null);
    };

    return (
        <Modal
            actions={[
                {
                    id: "add",
                    children: t("common.add"),
                    loading: isInProgress,
                    onClick: () => {
                        void onSubmit();
                    },
                },
            ]}
            disabled={isInProgress}
            footerError={modalError}
            isOpen={isOpen}
            title={t("groups.AddLocationsModal.title")}
            onClose={closeModal}
        >
            <Stack direction="column" gap="S" paddingTop="S">
                <Box>
                    <Dropdown
                        disabled={!groups.length || isInProgress}
                        label={t("groups.addLocationsModal.dropdown.label")}
                        invalid={!!groupDropdownError}
                        maxHeight={300}
                        options={groupsOptions}
                        placeholder={dropdownPlaceholder}
                        value={selectedGroupId ?? null}
                        onChange={onGroupSelection}
                        sheetCancelLabel={t("common.cancel")}
                    />
                    {!!groupDropdownError && (
                        <Box marginVertical="XXS" marginHorizontal="S">
                            <Text color="error" micro>
                                {groupDropdownError}
                            </Text>
                        </Box>
                    )}
                </Box>
                <CreateGroupInputList
                    disabled={isInProgress}
                    addGroupBtnLabel={t("groups.AddLocationsModal.addAnotherGroupButton")}
                    gap="XS"
                    groupsEditor={groupsEditor}
                    allowFirstGroupDeletion
                    onGroupAdd={onNewGroupInputAdd}
                />
                <Stack mt="XS">
                    <ChipList disabled={isInProgress} label={t("groups.locations")} locations={modalSelectedLocations} onRemove={onRemoveLocation} />
                </Stack>
            </Stack>
        </Modal>
    );
};

export default AddLocationsModal;
