import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { RoleModel, RoleName } from "../../models/RoleModel";
import { AuthRecordModel } from "../../models/AuthRecordModel";
import { UserRecordModel } from "../../models/UserRecordModel";

export const authorizationApi = createApi({
    reducerPath: "authorizationApi",
    baseQuery,
    tagTypes: ["UserRecords"],
    endpoints: (builder) => ({
        getAuthorizationRecords: builder.query<AuthRecordModel[], string>({
            query: (userId) => ({
                url: `/v1/authorization/records/byUserId/${userId}`,
            }),
            transformResponse: (records: AuthRecordModel[]) => {
                return records?.filter((r) => r.roleName === RoleName.OrganizationAdmin);
            },
        }),
        getUserRecords: builder.query<UserRecordModel[], { locationId: string }>({
            query: ({ locationId }) => ({
                url: `/v1/authorization/records/usersbylocationid/${locationId}`,
            }),
            providesTags: ["UserRecords"],
        }),
        getAuthorizedRoles: builder.query<RoleModel[], { userId: string; locationId: string }>({
            query: ({ userId, locationId }) => ({
                url: `/v1/authorization/roles/assignableUserRolesByLocation/${userId}/${locationId}`,
            }),
        }),
        deleteAuthorization: builder.mutation<RoleModel[], { userId: string; locationId: string; authId: string }>({
            query: ({ locationId, userId, authId }) => ({
                url: `/v1/authorization/records/${locationId}/${authId}`,
                method: "DELETE",
                body: { locationId, userId, authId },
            }),
        }),
        postAuthorization: builder.mutation<RoleModel[], { userId: string; locationId: string; roleName: string }>({
            query: ({ locationId, userId, roleName }) => ({
                url: `/v1/authorization/records/${locationId}`,
                method: "POST",
                body: { userId, roleName },
            }),
        }),
        getAuthRecordsByLocation: builder.query<unknown, { id: string }>({
            query: ({ id }) => ({
                url: `/v1/authorization/records/byLocationId/${id}`,
            }),
        }),
    }),
});

export const {
    useGetAuthorizationRecordsQuery,
    useLazyGetAuthorizationRecordsQuery,
    useGetUserRecordsQuery,
    useGetAuthorizedRolesQuery,
    useLazyGetAuthorizedRolesQuery,
    useDeleteAuthorizationMutation,
    usePostAuthorizationMutation,
    useLazyGetAuthRecordsByLocationQuery,
} = authorizationApi;
