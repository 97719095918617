import React from "react";
import { useRoutes } from "react-router-dom";
import GroupsPage from "./pages/GroupsPage";
import NotFoundPage from "./pages/NotFoundPage";

const Router: React.FC = () => {
    const routes = [
        { path: "/", element: <GroupsPage /> },
        { path: "*", element: <NotFoundPage /> },
    ];

    return useRoutes(routes);
};

export default Router;
