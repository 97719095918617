export enum IntercomTopic {
    EMBEDDED_APP_ACCESS_EXPIRED = "EMBEDDED_APP_ACCESS_EXPIRED",
    REDIRECT = "REDIRECT",
}

export interface IntercomPayload {
    topic: IntercomTopic;
    message: string;
    timestamp?: Date;
}

export interface IntercomReceivedMessage {
    origin: string;
    data: IntercomPayload;
}

export interface IntercomContextModel {
    postMessage: (topic: IntercomTopic, message: string) => void;
}
