import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";
import { PagePadding } from "../styles/variables/layout";

export const Wrapper = styled.div<{ isOpen: boolean }>`
    position: absolute;
    overflow: hidden;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    background-color: ${Palette.Navy700};
    transition: right 0.3s linear;
    right: ${({ isOpen }) => (isOpen ? "0" : "-50%")};

    @media (max-width: ${Breakpoints.M}) {
        width: 100%;
        right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    }
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Body = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 0 ${PagePadding.L}px ${Spacing.L}px;

    @media (max-width: ${Breakpoints.L}) {
        padding: 0 ${PagePadding.M}px ${Spacing.M}px;
    }
`;

export const Header = styled(Stack)<{ scrolled: boolean }>`
    padding: ${({ scrolled }) => (scrolled ? `${Spacing.S}px ${PagePadding.L}px` : `${Spacing.L}px ${PagePadding.L}px`)};
    box-shadow: ${({ scrolled }) => (scrolled ? `0px 2px 8px rgba(0, 0, 0, 0.25);` : `none`)};

    @media (max-width: ${Breakpoints.L}) {
        padding: ${({ scrolled }) => (scrolled ? `${Spacing.XS}px ${PagePadding.M}px` : `${Spacing.S}px ${PagePadding.M}px`)};
    }
`;

export const Footer = styled(Stack)`
    padding: ${PagePadding.L}px;

    @media (max-width: ${Breakpoints.L}) {
        padding: ${Spacing.L}px ${Spacing.M}px;
    }
`;
