import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";

const selectSortedSelectedLocations = createSelector(
    [({ organizationEditor: { selectedLocationIds } }: RootState) => selectedLocationIds, ({ locations: { locations } }: RootState) => locations],
    (selectedLocationIds, locations) => {
        const selectedLocations = locations.filter((n) => selectedLocationIds.includes(n.id));
        return selectedLocations.sort((a, b) => a.name.localeCompare(b.name));
    }
);

const selectSelectedClientId = ({ organizationEditor: { selectedClientId } }: RootState) => selectedClientId;

const selectSelectedClient = createSelector(
    [({ organizationEditor: { selectedClientId } }: RootState) => selectedClientId, ({ organization: { authorizedNodes } }: RootState) => authorizedNodes],
    (selectedClientId, nodes) => {
        return nodes.find((n) => n.id === selectedClientId);
    }
);

export default {
    selectSelectedClient,
    selectSelectedClientId,
    selectSortedSelectedLocations,
};
