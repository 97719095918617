import React from "react";
import { Box, ButtonText, ResponsiveType, Separator, SpacingType, Stack } from "@secuis/ccp-react-components";
import { GroupsEditor } from "../hooks/EditGroupHooks";
import CreateGroupInput from "./CreateGroupInput";

interface GroupInputListProps {
    addGroupBtnLabel: string;
    gap: ResponsiveType<SpacingType>;
    groupsEditor: GroupsEditor;
    allowFirstGroupDeletion?: boolean;
    disabled?: boolean;
    onGroupAdd?: () => void;
}

const CreateGroupInputList: React.FC<GroupInputListProps> = ({
    addGroupBtnLabel,
    gap,
    groupsEditor,
    allowFirstGroupDeletion = false,
    disabled = false,
    onGroupAdd,
}) => {
    const onClickHandle = () => {
        groupsEditor.onAddNewGroup();
        if (onGroupAdd) {
            onGroupAdd();
        }
    };
    return (
        <Stack direction="column" gap={gap}>
            {[...groupsEditor.editors.keys()].map((key) => {
                const entry = groupsEditor.editors.get(key);
                return [
                    <CreateGroupInput
                        key={key}
                        disabled={disabled}
                        errors={entry.errors}
                        showDelete={allowFirstGroupDeletion || key > 1}
                        value={entry.value}
                        onDelete={() => groupsEditor.onDeleteGroup(key)}
                        onInputChange={(value: string) => groupsEditor.onGroupNameChange(key, value)}
                        onKeyDown={(event) => groupsEditor.onGroupNameKeyDown(key, event)}
                    />,
                    <Separator key={`${key}_sep`} />,
                ];
            })}
            <Box ml="XS">
                <ButtonText disabled={disabled} color="accent" icon="Plus" onClick={onClickHandle}>
                    {addGroupBtnLabel}
                </ButtonText>
            </Box>
        </Stack>
    );
};

export default CreateGroupInputList;
