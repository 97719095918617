import React from "react";
import { useTranslation } from "react-i18next";
import PageCentered from "../components/PageCentered";

const FailurePage: React.FC = () => {
    const { t } = useTranslation();

    return <PageCentered title={t("failurePage.title")} message={t("failurePage.message")} />;
};

export default FailurePage;
