import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";
import { mapGroupsToTreeFromRoot } from "../../helpers/GroupsHelpers";

const selectGroups = ({ groups: { groups } }: RootState) => groups;
const selectIsLoading = ({ groups: { isLoading } }: RootState) => isLoading;
const selectGroupsTree = createSelector(
    [({ organizationEditor: { selectedClientId } }: RootState) => selectedClientId, ({ groups: { groups } }: RootState) => groups],
    (selectedClientId, groups) => mapGroupsToTreeFromRoot(groups, selectedClientId)
);

export default {
    selectGroups,
    selectIsLoading,
    selectGroupsTree,
};
