import { createApi } from "@reduxjs/toolkit/query/react";
import { BrandingInfoDTO, BrandingInfoModel } from "../../models/BrandingInfoModel";
import baseQuery from "./baseQuery";

export const appApi = createApi({
    reducerPath: "appApi",
    baseQuery,
    endpoints: (builder) => ({
        getBrandingInfo: builder.query<BrandingInfoModel, string>({
            query: (userId) => ({
                url: `/v1/branding/users/${userId}`,
            }),
            transformResponse: (response: BrandingInfoDTO) => ({
                ...response,
                companyName: response.name,
            }),
            transformErrorResponse: (response) => ({
                ...response,
                hasCustomErrorHandling: true,
            }),
        }),
    }),
});

export const { useGetBrandingInfoQuery } = appApi;
