import { Modal, Text } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

interface DeleteGroupModalProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const DeleteGroupModal = ({ isOpen, onClose, onConfirm, isLoading }: DeleteGroupModalProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            size="S"
            title={t("groups.deleteGroup.modalTitle")}
            onClose={onClose}
            actions={[
                {
                    children: t("common.deleteAll"),
                    onClick: onConfirm,
                    loading: isLoading,
                },
                {
                    children: t("common.cancel"),
                    onClick: onClose,
                },
            ]}
        >
            <Text>{t("groups.deleteGroup.modalBodyText")}</Text>
        </Modal>
    );
};
