import { Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

interface CardGridProps {
    selected: boolean;
}

export const CardGrid = styled.div<CardGridProps>`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    column-gap: ${Spacing.S}px;
    row-gap: ${Spacing.XS}px;
    background-color: ${Palette.Navy750};
    border: ${(props) => (props.selected ? `2px solid ${Palette.Navy300}` : "2px solid transparent")};
    border-radius: 4px;
    padding: ${Spacing.S}px;
    cursor: pointer;

    :active {
        background-color: ${Palette.Navy850};
    }

    @media (hover: hover) and (pointer: fine) {
        :hover {
            background-color: ${Palette.Navy800};
        }
    }
`;

export const BadgeCell = styled.div`
    grid-column: 2;
`;
