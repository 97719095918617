import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLazyGetUserQuery } from "../services/UserApi";
import AccessSelectors from "../selectors/AccessSelectors";
import UserSelectors from "../selectors/UserSelectors";

export const useUser = () => {
    const userId = useSelector(AccessSelectors.selectUserId);
    const [getUser, { data: user, isLoading, isError, isUninitialized }] = useLazyGetUserQuery();

    useEffect(() => {
        if (userId && isUninitialized) {
            getUser(userId);
        }
    }, [isUninitialized, userId]);

    return { user, isUninitialized, isLoading, isError };
};

export const useUserLanguage = () => {
    const { i18n } = useTranslation();
    const languagePreference = useSelector(UserSelectors.selectUserPreference(process.env.REACT_APP_LANGUAGE_PREFERENCE_KEY));

    useEffect(() => {
        if (languagePreference && languagePreference !== i18n.language) {
            i18n.changeLanguage(languagePreference);
        }
    }, [languagePreference, i18n]);
};
