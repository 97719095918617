import { createSelector } from "@reduxjs/toolkit";
import { NodeLabel } from "../../models/OrganizationNodeModel";
import { RootState } from "../../Store";

const selectAuthorizedNodes = createSelector([({ organization: { authorizedNodes } }: RootState) => authorizedNodes], (authorizedNodes) => authorizedNodes);

const selectAuthorizedClients = createSelector([({ organization: { authorizedNodes } }: RootState) => authorizedNodes], (authorizedNodes) => {
    return authorizedNodes.filter(({ label }) => label === NodeLabel.Customer || label === NodeLabel.Client).sort((a, b) => a.name.localeCompare(b.name));
});

export default {
    selectAuthorizedNodes,
    selectAuthorizedClients,
};
