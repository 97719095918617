import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Checkbox, Text } from "@secuis/ccp-react-components";
import { StatusCell, SiteCell, DataCell } from "./LocationsTableRow.styles";
import { LocationType } from "../../models/LocationModel";

const noop = () => undefined;

interface LocationTableRowProps {
    location: LocationType;
    isSelected: boolean;
    onSelect: (id: string) => void;
}

export const LocationsTableRow = ({ location: { id, name, address, country, number, groups }, isSelected, onSelect }: LocationTableRowProps): JSX.Element => {
    const { t } = useTranslation();
    const renderCellValue = (value?: string) => value || t("common.noDataFound");

    return (
        <React.Fragment key={id}>
            <SiteCell isSelected={isSelected}>
                <Checkbox key={`${id}${isSelected.toString()}`} label={name} value={id} onClick={() => onSelect(id)} onChange={noop} checked={isSelected} />
            </SiteCell>
            <DataCell isSelected={isSelected}>
                <Text color="neutral">{renderCellValue(address)}</Text>
            </DataCell>
            <DataCell isSelected={isSelected}>
                <Text color="neutral">{renderCellValue(country)}</Text>
            </DataCell>
            <DataCell isSelected={isSelected}>
                <Text color="neutral">{renderCellValue(number)}</Text>
            </DataCell>
            <StatusCell isSelected={isSelected}>
                {groups.map((group) => (
                    <Badge key={group.label} mode="outlined" color={group.color} label={group.label} />
                ))}
            </StatusCell>
        </React.Fragment>
    );
};
