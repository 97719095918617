import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Text } from "@secuis/ccp-react-components";
import { Banner } from "./SelectClientBanner.styles";
import BannerIllustration from "../../BannerIllustration";

const SelectClientBanner: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Banner gap="M" padding="M" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Text color="secondary">{t("banner.label")}</Text>
            <Stack direction="row" gap="XXL">
                <BannerIllustration label={t("banner.members")} variant="Members" />
                <BannerIllustration label={t("banner.filter")} variant="Filter" />
                <BannerIllustration label={t("banner.insights")} variant="Insights" />
            </Stack>
        </Banner>
    );
};

export default SelectClientBanner;
