import { isWithinInterval, subDays } from "date-fns";
import { TFunction } from "i18next";
import { SignalColor } from "@secuis/ccp-react-components";
import { GroupModel } from "../models/GroupModel";
import { NodeModel } from "../models/OrganizationNodeModel";
import { LocationType } from "../models/LocationModel";

const NEW_STATUS_DAYS_LIMIT = 30;

export enum StatusSort {
    unassigned = "unassigned",
    new = "new",
    groupAsc = "groupAsc",
    groupDesc = "groupDesc",
}
export type SortOrderType = "asc" | "desc" | StatusSort;

export const getCountryName = (code: string, language: string) => new Intl.DisplayNames([language], { type: "region" }).of(code);

export const getGroupNames = (
    groups: GroupModel[],
    location: NodeModel,
    t: TFunction
): { color: SignalColor; label: string; isUnassigned?: boolean; isNew?: boolean }[] => {
    const statuses = groups
        .filter((g) => g.locationIds.includes(location.id))
        .map((g) => g.name)
        .sort((a, b) => a.localeCompare(b));

    if (statuses.length) {
        return statuses.map((s) => ({ color: "neutral", label: s }));
    }
    const isNew = isWithinInterval(new Date(location.timestamp), { start: subDays(new Date(), NEW_STATUS_DAYS_LIMIT), end: new Date() });
    const unassignedStatus = {
        color: "medium" as SignalColor,
        label: t("locations.unassigned"),
        isUnassigned: true,
    };
    const newStatus = {
        color: "low" as SignalColor,
        label: t("common.new"),
        isNew: true,
    };
    if (isNew) {
        return [newStatus, unassignedStatus];
    }
    return [unassignedStatus];
};

const sortWithDirection = (order: SortOrderType, sortBy: string) => (a: LocationType, b: LocationType) => {
    if (order === "desc") {
        return b[sortBy].localeCompare(a[sortBy]);
    }
    return a[sortBy].localeCompare(b[sortBy]);
};

const sortByGroups = (order: SortOrderType) => (a: LocationType, b: LocationType) => {
    const [groupA] = a.groups;
    const [groupB] = b.groups;
    if (order === StatusSort.groupAsc) {
        return groupA.label.localeCompare(groupB.label);
    }
    if (StatusSort.groupDesc) {
        return groupB.label.localeCompare(groupA.label);
    }
};

const sortByStatus = (order: SortOrderType, locations: LocationType[]) => {
    const unassigned = locations.filter((l) => l.groups.length === 1 && l.groups[0].isUnassigned);
    const newLocations = locations.filter((l) => l.groups.length === 2 && l.groups.find((g) => g.isNew));
    const assignedLocations = locations.filter((l) => !l.groups[0].isUnassigned && !l.groups[0].isNew);

    if (order === StatusSort.unassigned) {
        return unassigned.concat(newLocations).concat([...assignedLocations].sort(sortByGroups(StatusSort.groupAsc)));
    }
    if (order === StatusSort.new) {
        return newLocations.concat(unassigned).concat([...assignedLocations].sort(sortByGroups(StatusSort.groupAsc)));
    }
    if (order === StatusSort.groupAsc) {
        return [...assignedLocations].sort(sortByGroups(order)).concat(newLocations).concat(unassigned);
    }
    if (order === StatusSort.groupDesc) {
        return [...assignedLocations].sort(sortByGroups(order)).concat(newLocations).concat(unassigned);
    }
    return locations;
};

export const sortLocations = (sortConfig: { order: SortOrderType; sortBy: string }, locations: LocationType[]): LocationType[] => {
    const sortedLocations = [...locations].sort((a, b) => {
        if (sortConfig.sortBy === "groupsSort") {
            return 0;
        }
        return sortWithDirection(sortConfig.order, sortConfig.sortBy)(a, b);
    });

    if (sortConfig.sortBy === "groupsSort") {
        return sortByStatus(sortConfig.order, sortedLocations);
    }

    return sortedLocations;
};
