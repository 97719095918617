import React from "react";
import { ButtonText } from "@secuis/ccp-react-components";

interface ISortButtonProps {
    label: string;
    isActive: boolean;
    sortAsc: boolean;
}

export const SortButton = ({ label, isActive, sortAsc }: ISortButtonProps) => {
    return (
        <ButtonText small icon={isActive ? (sortAsc ? "SortArrowDown" : "SortArrowUp") : "SortArrowDown"} color={isActive ? "accent" : "primary"}>
            {label}
        </ButtonText>
    );
};
