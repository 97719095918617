import { AnyAction, isAsyncThunkAction, isFulfilled, Middleware, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";
import { hasTokenExpired, hasTokenHalftimePassed } from "../../helpers/TokenHelper";
import { RootState } from "../../Store";
import { accessApi, AccessResponse } from "../services/AccessApi";

function isTokenResponse(action: PayloadAction<AccessResponse>): action is PayloadAction<AccessResponse> {
    return !!action.payload?.token;
}

const refreshMiddleware: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
    (next) =>
    (action) => {
        // do not refresh after access token responses
        if (isAsyncThunkAction(action) && isFulfilled(action) && !isTokenResponse(action)) {
            const state = getState() as RootState;
            const { accessTokenDecoded } = state.access;
            if (accessTokenDecoded) {
                const isTokenExpired = hasTokenExpired(accessTokenDecoded);
                if (!isTokenExpired) {
                    const isLifespanPastHalf = hasTokenHalftimePassed(accessTokenDecoded);
                    if (isLifespanPastHalf) {
                        dispatch(accessApi.endpoints.refreshAccessToken.initiate(undefined, { forceRefetch: true }) as unknown as AnyAction);
                    }
                }
            }
        }

        return next(action);
    };

export default refreshMiddleware;
