import styled from "styled-components";

export const Table = styled.div`
    height: 100%;
    overflow: auto;
`;

export const ParentDropdownWrapper = styled.div`
    width: 100%;
`;
