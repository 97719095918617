import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@secuis/ccp-react-components";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";
import { useSortedLocations } from "../store/hooks/LocationsHooks";

interface AddSitesToGroupButtonProps {
    onClick: () => void;
}

const AddSitesToGroupButton: React.FC<AddSitesToGroupButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();
    const selectedLocations = useSelector(OrganizationEditorSelectors.selectSortedSelectedLocations);
    const sortedLocations = useSortedLocations();

    const addSitesToGroupBtnLabel = useMemo(() => {
        const label = t("createGroupsPage.addSitesToGroup");
        if (!selectedLocations.length) {
            return label;
        }
        return `${label} (${selectedLocations.length})`;
    }, [selectedLocations, t]);

    return (
        <Button icon="Plus" mode="contained" fitContent disabled={!selectedLocations.length || !sortedLocations.length} onClick={onClick}>
            {addSitesToGroupBtnLabel}
        </Button>
    );
};

export default AddSitesToGroupButton;
