import { Box, Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Table = styled.div`
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: auto auto 200px 200px 240px;
    grid-auto-rows: min-content;
    padding-right: ${Spacing.S}px;

    ::-webkit-scrollbar-track {
        margin-top: 52px;
    }
    @media (max-width: ${Breakpoints.L}) {
        grid-template-columns: 340px 260px 200px 200px 240px;
    }
`;

export const HeaderCell = styled(Box)`
    position: sticky;
    top: 0;
    padding: ${Spacing.S}px ${Spacing.M}px;
    background-color: ${Palette.Navy800};
    z-index: 1;
`;

export const SearchWrapper = styled(Box)`
    flex-grow: 1;
`;
