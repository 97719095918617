import React, { useMemo, useState } from "react";
import MinWidthPage from "./MinWidthPage";
import SelectClientStep from "../components/GroupsSteps/SelectClient/SelectClient";
import CreateGroups from "../components/GroupsSteps/CreateGroups";

const GroupsPage: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const allSteps = useMemo(() => {
        return [<SelectClientStep key="1" onContinueClick={() => setCurrentStep(1)} />, <CreateGroups key="2" onBackClick={() => setCurrentStep(0)} />];
    }, []);

    const stepToRender = useMemo(() => {
        return allSteps[currentStep];
    }, [allSteps, currentStep]);

    return <MinWidthPage>{stepToRender}</MinWidthPage>;
};

export default GroupsPage;
