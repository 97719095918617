import { useCallback, useContext, useMemo } from "react";
import { GroupsEditor } from "./EditGroupHooks";
import { ToasterContext } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useAddLocationsToGroupMutation, useCreateGroupsMutation, useLazyGetChildLocationsQuery } from "../store/services/OrganizationApi";
import { useSelector } from "react-redux";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";
import { ErrorData } from "../store/services/Models";
import { NodeLabel, NodeModel } from "../models/OrganizationNodeModel";

export const useAddLocations = (groupsEditor: GroupsEditor, modalSelectedLocations: NodeModel[], selectedGroupId: string) => {
    const { t } = useTranslation();
    const { addToast } = useContext(ToasterContext);
    const selectedClient = useSelector(OrganizationEditorSelectors.selectSelectedClient);
    const clientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const [addLocationsToGroup, { isLoading: addingLocationsToGroup }] = useAddLocationsToGroupMutation();
    const [createGroupsBatch, { isLoading: creatingGroupsInProgress }] = useCreateGroupsMutation();
    const [getChildLocationsQuery] = useLazyGetChildLocationsQuery();

    const handleLocationsAssignments = useCallback(async () => {
        const locationIds = modalSelectedLocations.map((l) => l.id);
        try {
            await addLocationsToGroup({ groupId: selectedGroupId, locationIds }).unwrap();
            addToast({ title: t("groups.addLocations.successMessage", { count: locationIds.length }), preserveOnHover: false, type: "success" });
        } catch (error: unknown) {
            const { status } = error as ErrorData;
            if (status === 401) {
                return;
            }
            const { errors } = (error["data"] ?? {}) as ErrorData;
            if (errors?.LocationIds) {
                for (const errorKey of errors.LocationIds) {
                    if (errorKey === "InvalidVertexError") {
                        addToast({
                            type: "high",
                            title: t("error.invalidLocation.title"),
                            message: t("error.invalidLocation.message"),
                            actions: [
                                {
                                    label: t("common.reload"),
                                    onClick: () => {
                                        const label = selectedClient.label === NodeLabel.Client ? NodeLabel.SiteObject : NodeLabel.CustomerObject;
                                        getChildLocationsQuery({ clientId: selectedClient.id, childLabel: label });
                                    },
                                },
                            ],
                        });
                    }
                }
                return;
            }
            throw new Error(t("groups.addLocationsModal.unknownError"));
        }
    }, [groupsEditor, modalSelectedLocations, selectedGroupId, addLocationsToGroup, addToast, selectedClient, selectedClient, getChildLocationsQuery]);

    const handleNewGroups = useCallback(async () => {
        const createGroupRequests = [...groupsEditor.editors].map((e) => ({
            parentId: clientId,
            name: e[1].value.trim(),
            locationIds: modalSelectedLocations.map((l) => l.id),
        }));
        const results = await createGroupsBatch(createGroupRequests).unwrap();
        const hasErrors = results.some((r) => r.error);
        const hasAccessDenied = results.some((r) => r.error && r.error.status === 401);

        if (hasAccessDenied) {
            addToast({ title: t("common.accessDenied"), type: "high" });
            return;
        }

        if (hasErrors) {
            results.forEach((r, i) => {
                if (!r.error) {
                    groupsEditor.onDeleteGroup(i + 1);
                }
            });
            throw new Error(t("groups.addLocationsModal.notAllGroupsCreatedError"));
        }
        addToast({ title: t("groups.addGroups.successMessage", { count: groupsEditor.editors.size }), preserveOnHover: false, type: "success" });
        addToast({ title: t("groups.addLocations.successMessage", { count: modalSelectedLocations.length }), preserveOnHover: false, type: "success" });
    }, [groupsEditor, modalSelectedLocations, clientId, addToast, createGroupsBatch]);

    const isInProgress = useMemo(() => {
        return addingLocationsToGroup || creatingGroupsInProgress;
    }, [addingLocationsToGroup, creatingGroupsInProgress]);

    const addLocations = async () => {
        if (groupsEditor.editors.size) {
            await handleNewGroups();
        }
        if (selectedGroupId) {
            await handleLocationsAssignments();
        }
    };

    return {
        addLocations,
        isInProgress,
    };
};
