import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isPrintableCharacter } from "../helpers/KeyCodeHelper";
import { GroupEditorType, GroupTreeModel } from "../models/GroupModel";
import { useSelector } from "react-redux";
import GroupsSelectors from "../store/selectors/GroupsSelectors";
import { useDeleteGroupLocationsMutation, useEditGroupNameMutation, useEditGroupParentMutation } from "../store/services/OrganizationApi";
import { formatNameRequestParam } from "../helpers/GroupsHelpers";
import OrganizationEditorSelectors from "../store/selectors/OrganizationEditorSelectors";

export type GroupsEditor = {
    editors: Map<number, CreateGroupEntry>;
    validationFunctions: {
        noEmptyValueValidator: (entry: CreateGroupEntry) => string;
    };
    onAddNewGroup: () => void;
    onDeleteGroup: (groupKey: number) => void;
    onGroupNameChange: (groupKey: number, value: string) => void;
    onGroupNameKeyDown: (editorKey: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
    resetEditors: () => void;
    validateGroups: (groupValidationFunc: (input: CreateGroupEntry) => string) => boolean;
    setEditors: (arg: Map<number, CreateGroupEntry>) => void;
};

export interface CreateGroupEntry {
    value: string;
    errors: string[];
}

export const useGroupsEditor = (initialState: Map<number, CreateGroupEntry>): GroupsEditor => {
    const [editors, setEditors] = useState<Map<number, CreateGroupEntry>>(initialState);
    const { t } = useTranslation();

    const onAddNewGroup = () => {
        const lastEditor = [...editors][editors.size - 1];
        const nextId = lastEditor ? lastEditor[0] + 1 : 1;
        setEditors(new Map(editors.set(nextId, { value: "", errors: [] })));
    };

    const onDeleteGroup = (groupKey: number) => {
        editors.delete(groupKey);
        setEditors(new Map(editors));
    };

    const onGroupNameChange = (groupKey: number, value: string) => {
        setEditors(new Map(editors.set(groupKey, { ...editors.get(groupKey), value })));
    };

    const onGroupNameKeyDown = (editorKey: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isPrintableCharacter(event.key)) {
            setEditors(editors.set(editorKey, { ...editors.get(editorKey), errors: [] }));
        }
    };

    const resetEditors = () => {
        setEditors(initialState);
    };

    const validateGroups = (groupValidationFunc: (input: CreateGroupEntry) => string) => {
        const invalidInputs = [...editors]
            .map((entry) => ({
                key: entry[0],
                error: groupValidationFunc(entry[1]),
            }))
            .filter((e) => e.error);
        if (!invalidInputs.length) {
            return true;
        }
        invalidInputs.forEach((k) => {
            editors.set(k.key, { ...editors.get(k.key), errors: [k.error] });
        });
        setEditors(new Map(editors));
        return false;
    };

    const validationFunctions = {
        noEmptyValueValidator: (entry: CreateGroupEntry) => {
            if (entry.value.trim()) {
                return null;
            }
            return t("groups.validationErrors.emptyValue");
        },
    };

    return { editors, validationFunctions, onAddNewGroup, onDeleteGroup, onGroupNameChange, onGroupNameKeyDown, resetEditors, validateGroups, setEditors };
};

export const useEditGroupBatch = (editor?: GroupEditorType, group?: GroupTreeModel) => {
    const groupList = useSelector(GroupsSelectors.selectGroups);
    const rootId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const [isLoading, setIsloading] = useState(false);
    const [editGroupName] = useEditGroupNameMutation();
    const [editGroupParent] = useEditGroupParentMutation();
    const [deleteGroupLocations] = useDeleteGroupLocationsMutation();

    const { name, id: groupId, locationsToDelete, parentId: editorParentId, childrenToAdd, childrenToRemove } = editor || {};
    const { name: originalName, parentId: originalParentId } = group || {};

    const editGroup = useCallback(async () => {
        const nameValue = name !== originalName ? name : null;
        const changedParentId = editorParentId !== originalParentId ? editorParentId : null;
        const group = groupList.find((g) => g.id === groupId);
        const regexName = nameValue || group.name;
        const parentId = changedParentId || group.parentId;
        const newName = nameValue || changedParentId ? formatNameRequestParam(regexName, parentId, groupList) : null;
        try {
            setIsloading(true);
            if (newName) {
                await editGroupName({ groupId, name: newName }).unwrap();
            }
            if (changedParentId) {
                await editGroupParent({ groupId, parentId }).unwrap();
            }
            if (locationsToDelete.length) {
                await deleteGroupLocations({ groupId, locationIds: locationsToDelete }).unwrap();
            }

            for (const child of childrenToRemove) {
                const childName = groupList.find((g) => g.id === child).name;
                const newChildName = formatNameRequestParam(childName, rootId, groupList);
                await editGroupParent({
                    groupId: child,
                    parentId: rootId,
                }).unwrap();
                if (childName !== newChildName) {
                    await editGroupName({
                        groupId: child,
                        name: newChildName,
                    }).unwrap();
                }
            }
            for (const child of childrenToAdd) {
                const childName = groupList.find((g) => g.id === child).name;
                const newChildName = formatNameRequestParam(childName, group.id, groupList);
                await editGroupParent({
                    groupId: child,
                    parentId: groupId,
                }).unwrap();
                if (childName !== newChildName) {
                    await editGroupName({
                        groupId: child,
                        name: newChildName,
                    }).unwrap();
                }
            }
        } finally {
            setIsloading(false);
        }
    }, [group, editor]);

    return {
        editGroup,
        isLoading,
    };
};
