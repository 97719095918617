import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrganizationEditorStateModel {
    selectedClientId: string;
    selectedLocationIds: string[];
}

export const initialState: OrganizationEditorStateModel = {
    selectedClientId: null,
    selectedLocationIds: [],
};

export const organizationEditorSlice = createSlice({
    name: "organizationEditor",
    initialState,
    reducers: {
        setClient(state, { payload }: PayloadAction<string>) {
            state.selectedClientId = payload;
            state.selectedLocationIds = [];
        },
        setSelectedLocationIds(state, { payload }: PayloadAction<string[]>) {
            state.selectedLocationIds = payload;
        },
    },
});

export const organizationEditorActions = organizationEditorSlice.actions;
