import { Stack } from "@secuis/ccp-react-components";
import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const CenteredContent: React.FC = ({ children }) => {
    return (
        <StyledDiv>
            <Stack flex="1" marginVertical="L" justifyContent="center" alignItems="center">
                {children}
            </Stack>
        </StyledDiv>
    );
};
