import { RootState } from "../../Store";

const selectIsAccessExpired = ({ access: { isAccessExpired } }: RootState) => isAccessExpired;

const selectUserId = ({ access: { accessTokenDecoded } }: RootState) => accessTokenDecoded?.UserId;

export default {
    selectIsAccessExpired,
    selectUserId,
};
