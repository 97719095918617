import { PropertyValueModel } from "./PropertyValueModel";

export enum NodeLabel {
    Client = "client",
    Country = "country",
    Area = "area",
    Branch = "branch",
    SubBranch = "subBranch",
    CostCenter = "costCenter",
    Customer = "customer",
    CustomerObject = "customerObject",
    SiteObject = "siteObject",
    Service = "service",
}

export interface NodeDTO {
    id: string;
    label: NodeLabel;
    properties: Record<string, PropertyValueModel[]>;
    hasChildren?: boolean;
}

export interface NodeModel {
    id: string;
    label: NodeLabel;
    labelDisplay: string;
    hasChildren: boolean;
    isDeleted?: boolean;
    // common properties
    name: string;
    countryCode: string;
    // common location properties
    address?: string;
    number?: string;
    timestamp?: string;
}
