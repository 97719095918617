import { createSlice } from "@reduxjs/toolkit";
import { organizationApi } from "../services/OrganizationApi";
import { NodeModel } from "../../models/OrganizationNodeModel";
import { mapToOrganizationNode } from "../../helpers/OrganizationNodeHelper";

interface OrganizationStateModel {
    authorizedNodes: NodeModel[];
}

export const initialState: OrganizationStateModel = {
    authorizedNodes: [],
};

export const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(organizationApi.endpoints.getAuthorizedNodes.matchFulfilled, (state, action) => {
            const nodes = action.payload?.map((val) => mapToOrganizationNode(val)).filter(({ isDeleted }) => !isDeleted);
            state.authorizedNodes = nodes;
        });
    },
});

export const organizationActions = organizationSlice.actions;
